import React from 'react';
import './Payment.scss';

export default function Payment() {

    const openStirpe = () => {
        window.open("https://www.stripe.com");
    }

    return (
        <div>
            <img alt="Logo of Stripe." onClick={() => openStirpe()} className="stripe-logo" src={require("../../images/logo-stripe.png")} />
            <span>We made sure that we have the most secure payment option for you.</span>
        </div>
    )
}
import React from "react";
import {
  Container,
  Button,
  Snackbar,
  Checkbox,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Grid,
} from "@material-ui/core";
import "./SummaryPage.scss";
import { RouteComponentProps } from "react-router-dom";
import { RentType, CarType, PriceResponse, PriceData } from "../dtos/PriceData";
import { loadStripe } from "@stripe/stripe-js";
import { axiosInstance } from "../config/Axios.config";
import PaymentData, { Preference } from "../dtos/PaymentData";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { SummaryActionTypes } from "../redux/actions";
import { RootState } from "../redux/reducers/rootReducer";
import { bindActionCreators, Dispatch } from 'redux';
import { SummaryState } from "../redux/reducers/summaryReducer";
import { clearState } from "../redux/actions"
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import PhoneInput from "react-phone-input-2";
import GeneralTermsComponent from "./Terms/GeneralTermsComponent";
import ServiceTermsComponent from "./Terms/ServiceTermsComponent";
import { ThumbDownSharp } from "@material-ui/icons";
import MetaTags from 'react-meta-tags';

const stripePromise = loadStripe(
  "pk_live_51HRJ0PHRem9AtehL0urpe6NCIOTF18gqDXoDMApYEcZsvmXuWJHGOHARN5d4t8i6OVQFGFzqgfMz9V6zIvRTMNHt00jOi7YsHC"!
);

export interface ISummaryPageProps {
  name: string;
  email: string;
  rentType: string;
  carType: string;
  origin: string;
  destination: string;
  date: string;
  time: string;
  price: string;
  preferences: any;
}

interface MappedProps {
  data: SummaryState
  email: string;
  name: string;
  userId: string;
  phone: string;
  token: string;
}

interface DispatchedProps {
  clearState: () => void
}

type Props = MappedProps & DispatchedProps;

class SummaryPage extends React.Component<Props,
  {
    orderNumber: string;
    open: boolean;
    errorMsg: string;
    checked: boolean;
    eighteenChecked: boolean;
    openGeneralTermsModal: boolean;
    openServiceTermsModal: boolean;
    comment: string;
    commentLength: number;
    discountCode: string;
    discountApplied: boolean;
    discountedPrice?: number;
    flightTrain: string;
    passengerName: string;
    passengerPhone: string;
    imTravelling: boolean;
  }
> {
  /**
   *
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      orderNumber: "",
      open: false,
      errorMsg: "",
      checked: false,
      eighteenChecked: false,
      openGeneralTermsModal: false,
      openServiceTermsModal: false,
      comment: "",
      commentLength: 0,
      discountCode: "",
      discountApplied: false,
      flightTrain: "",
      passengerName: "",
      passengerPhone: "",
      imTravelling: false
    };
  }

  componentDidMount() {
    window.scrollTo({top:0});
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked, errorMsg:"", open: false });
  };

  handleEighteenCheckboxChange = () => {
    this.setState({ eighteenChecked: !this.state.eighteenChecked, errorMsg:"", open: false });
  };

  handleImTravellingCheckboxChange = () => {
    if(this.state.imTravelling) {
      this.setState({imTravelling: false, passengerName: "", passengerPhone: ""})
    } else {
      this.setState({imTravelling: true, passengerName: this.props.name ? this.props.name : this.props.data.name, passengerPhone: this.props.phone ? this.props.phone : this.props.data.phone})
    }
  };

  handleCommentChange = (v: string) => {
    if(v.length <= 200)
      this.setState({comment: v, commentLength: v.length});
  }

  openGeneralTerms = () => {
    this.setState({ openGeneralTermsModal: true });
  };

  closeGeneralTerms = () => {
    this.setState({ openGeneralTermsModal: false });
  };

  openServiceTerms = () => {
    this.setState({ openServiceTermsModal: true });
  };

  closeServiceTerms = () => {
    this.setState({ openServiceTermsModal: false });
  };

  continueToPayment = async () => {
    if (this.state.checked && this.state.eighteenChecked) {
      if(!(this.props.email || this.props.data.email)) {
        this.setState({open: true, errorMsg: "Email address not provided."})
        return;
      }
        if((this.state.passengerName === "" || this.state.passengerPhone === "")) {
          this.setState({open: true, errorMsg: "Please fill out passenger data."})
          return;
        }
        const stripe = await stripePromise;
        var paymentData: PaymentData = {
          CarType: this.props.data.selectedType as CarType,
          Date: this.getDateJSON(),
          Destination: this.props.data.destination,
          Email: this.props.data.email !== "" ? this.props.data.email : this.props.email,
          Name: this.props.data.name !== "" ? this.props.data.name : this.props.name,
          Origin: this.props.data.origin,
          Price: this.state.discountApplied ? this.state.discountedPrice! : this.props.data.price as number,
          RentType: this.props.data.rentType,
          Hours: this.props.data.time as number,
          Preferences: this.props.data.preferences,
          Comment: this.state.comment,
          DiscountCode: this.state.discountApplied ? this.state.discountCode : "",
          FlightTrain: this.state.flightTrain,
          PassengerName: this.state.passengerName,
          PassengerPhone: this.state.passengerPhone,
          Phone: this.props.phone
        };
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + this.props.token;
        // Call your backend to create the Checkout Session—see previous step
        axiosInstance
          .post<string>("payment/checkout", paymentData)
          .then(async (res) => {
            const sessionId = res.data;
            // When the customer clicks on the button, redirect them to Checkout.
            const err = await stripe?.redirectToCheckout({
              sessionId,
            });
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
          })
          .catch((error) => {
            if(error.status === 419) {
              localStorage.clear();
              window.location.href = "/";
              return;
            }
            if (error.response?.data !== undefined) {
              this.setState({ open: true, errorMsg: "Something went wrong." });
            } else {
              this.setState({ open: true, errorMsg: "Cannot reach server." });
            }
          });
    } else {
      this.setState({open: true, errorMsg: "Please accept our terms and conditions"})
    }
  };

  formatPhoneNumber(phoneNumber: string) {
    return "+ " + phoneNumber.substring(0, 2) + " " + phoneNumber.substring(2);
  }

  checkDiscountCode() {
    axiosInstance.get("discount/check?code=" + this.state.discountCode + "&bookingDate=" + this.getDateJSON())
      .then(res => {
        var data: PriceData = {
          Date: this.props.data.date,
          Destination: this.props.data.destination,
          Origin: this.props.data.origin,
          RentType: this.props.data.rentType,
          Hours: this.props.data.time,
          DiscountCode: this.state.discountCode
        };

        axiosInstance
          .get<PriceResponse>("rental/price", { params: data })
          .then((res) => {
            switch(this.props.data.selectedType) {
              case CarType.Luxury:
                this.setState({discountedPrice: res.data.luxPrice});
                break;
              case CarType.Premium:
                this.setState({discountedPrice: res.data.premPrice});
                break;
              case CarType.SevenSeater:
                this.setState({discountedPrice: res.data.sevenSeaterPrice});
                break;
            }
            this.setState({discountApplied: true});
          })
          .catch(error => {
            if(error.response?.data !== undefined) {
              this.setState({errorMsg: error.response.data.message, open: true});
              return;
            }          
            else{
              this.setState({errorMsg: "Cannot reach server", open: true});
            }
          });
      })
      .catch(err => {
        this.setState({ open: true, errorMsg: err.response.data});
      })
  }

  discardDiscount() {
    this.setState({discountApplied: false, discountCode: ""});
  }

  getDateJSON() {
    return this.props.data.date.getFullYear() + "/" + (this.props.data.date.getMonth() + 1) + "/" + this.props.data.date.getDate() + " " + (this.props.data.date.getHours() % 12) + ":" + (this.props.data.date.getMinutes() < 10 ? ("0"+this.props.data.date.getMinutes()) : this.props.data.date.getMinutes()) + (this.props.data.date.getHours() >= 12 ? ' pm' : ' am');
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
            <meta name="description" content="Simber Chauffeur Services Booking summary page." />
            <title>Booking Summary | Simber</title>
            <link rel="canonical" href="https://www.simber.co.uk/summary" />
        </MetaTags>
        <HeaderComponent />
        <main>
          <Container>
            <h1 className="summary-header">Booking Summary</h1>
            <Container maxWidth="md" className="container-card" >
              <div className="summary-user-data">
                <Grid container spacing={0}>
                  <Grid className="header" xs={3} item><span>Name:</span></Grid>
                  <Grid className="data" xs={10} item>{this.props.data.name !==
                    "" ? (
                      <span className="name">
                        {this.props.data.name}
                      </span>
                    ) : (
                      this.props.name === "" ?
                      (
                        <span className="name">&#8212;</span>
                      ) :
                      (
                        <span className="name">{this.props.name}</span>
                      )
                    )}
                  </Grid>
                  <Grid className="header contacts-row" xs={3} item><span>Contact:</span></Grid>
                  <Grid className="data contacts-row" xs={10} item>
                    {this.props.data.phone !==
                      "" ? (
                        <span className="phone">
                          {this.formatPhoneNumber(this.props.data.phone)}
                        </span>
                      ) : (                    
                          <span className="phone">{this.props.phone}</span>
                      )}
                      {this.props.data.email !==
                      "" ? (
                        <span className="email">Email: {this.props.data.email}</span>
                      ) : (                    
                          <span className="email">Email: {this.props.email}</span>
                      )}
                      <div className="im-traveling">
                        <Checkbox
                          className="checkbox"
                          checked={this.state.imTravelling}
                          onChange={this.handleImTravellingCheckboxChange}
                        />
                        <span>I'm Travelling</span>
                      </div>
                  </Grid>
                  <Grid className="header passenger-contacts" xs={3} item><span>Passenger Name & Contact:</span></Grid>
                  <Grid className="data passenger-contacts-row" xs={10} item>
                    <input value={this.state.passengerName} onChange={(e) => {this.setState({ passengerName: e.currentTarget.value})}} className="name-input" placeholder="Passenger Name" />
                    <PhoneInput
                      enableSearch
                      regions={'europe'}
                      country={'gb'}
                      value={this.state.passengerPhone}
                      onChange={(v) => {this.setState({ passengerPhone: v})}}
                    />
                  </Grid>
                  <Grid className="header" xs={3} item><span className="flight-header">Flight, Train, or Cruise No.</span></Grid>
                  <Grid className="data" xs={10} item>
                    <input value={this.state.flightTrain} onChange={(e) => this.setState({ flightTrain: e.currentTarget.value})} className="flight-number" placeholder="Flight Number..." />
                  </Grid>
                </Grid>
              </div> 
              <div className="sumamry-booking-data">
                <Grid container spacing={0}>
                  <Grid xs={3} className="header" item><span>Time & Date:</span></Grid>
                  <Grid xs={10} className="data" item>
                    <span>{this.props.data.date.getDate() + "/" + (this.props.data.date.getMonth() + 1) + "/" + this.props.data.date.getFullYear() + " " + (this.props.data.date.getHours()) + ":" + (this.props.data.date.getMinutes() < 10 ? ("0"+this.props.data.date.getMinutes()) : this.props.data.date.getMinutes())}</span>
                  </Grid>
                  <Grid xs={3} className="header" item><span>Vehicle Type:</span></Grid>
                  <Grid xs={10} className="data" item>
                    <span>
                      { 
                        this.props.data.selectedType === 1
                          ? "Premium"
                          : this.props.data.selectedType === 2 
                          ? "Seven seater"
                          : "Luxury"
                      }
                    </span>
                  </Grid>
                  <Grid xs={3} className="header" item><span>From:</span></Grid>
                  <Grid xs={10} className="data" item>
                    <span>{this.props.data.origin}</span>
                  </Grid>
                  <Grid xs={3} className="header" item>
                    {this.props.data.rentType === RentType.Oneway && <span>Destination:</span>}
                    {this.props.data.rentType === RentType.ByTheHour && <span>Rent duration:</span>}
                  </Grid>
                  <Grid xs={10} className="data" item>
                    {this.props.data.rentType === RentType.Oneway &&
                      <span>{this.props.data.destination}</span>
                    }
                    {this.props.data.rentType === RentType.ByTheHour &&
                      <span>{this.props.data.time} hours</span>
                    }
                  </Grid>
                  <Grid xs={3} className="header" item><span>Flight, Train, or Cruise No.</span></Grid>
                  <Grid xs={10} className="data" item>
                    <span>{this.state.flightTrain == "" ? "-" : this.state.flightTrain}</span>
                  </Grid>
                  {this.props.data.preferences.some(p => p.Value) && 
                    <Grid xs={3} className="header" item><span>Selected Preferences:</span></Grid>
                  }
                  {this.props.data.preferences.some(p => p.Value) && 
                    <Grid xs={10} className="data" item>
                      {this.props.data.preferences.some(p => p.Value) &&
                      <div className="preferences">
                        {this.props.data.preferences.map(pref => (
                          pref.Value &&
                          <span id={"preference" + pref.Id}>{pref.Text + (this.props.data.preferences.filter(x => x.Value === true).indexOf(pref) !== (this.props.data.preferences.filter(x => x.Value === true).length-1) ? ", " : "")}</span>
                        ))}
                      </div>
                      }
                    </Grid>
                  }
                  <Grid xs={3} className="header" item><span>Additional<br /> Travel Info:</span></Grid>
                  <Grid xs={10} className="data" item>
                    <div className="comment-row">
                      <TextField
                      value={this.state.comment}
                      onChange={(v) => this.handleCommentChange(v.currentTarget.value)}
                      className="comment"
                      multiline
                      rows={4}
                      variant="outlined"
                      />
                      <span className="comment-length">{this.state.commentLength + "/200"}</span>
                    </div>
                  </Grid>
                  <Grid xs={3} className="header price" item><span>Price:</span></Grid>
                  <Grid xs={10} className="data price" item>
                    <span style={{textDecoration: this.state.discountApplied ? "line-through" : "none"}}>{this.props.data.price + " " + "GBP"}</span>
                    {this.state.discountApplied && 
                      <span> {this.state.discountedPrice + " " + "GBP"}</span>
                    }
                    <span style={{marginLeft:'15px'}} className='small-gold-text'>*All prices include VAT</span>
                  </Grid>
                  <Grid xs={3} className="header discount" item><span>Discount Code</span></Grid>
                  <Grid xs={10} className="data" item>
                    <div className="discount-wrapper">
                      {this.state.discountApplied 
                        ? (
                          <React.Fragment>
                            <span>{this.state.discountCode}</span>
                            <input className="accent-button discount-button" type="button" value="Discard" onClick={() => this.discardDiscount()}/>
                          </React.Fragment>
                        )
                        : (
                          <React.Fragment>
                            <input value={this.state.discountCode} onChange={(e) => {this.setState({ discountCode: e.currentTarget.value})}} className="discount-input" placeholder="Add Discount Code"/>
                            <input className="accent-button discount-button" type="button" value="Apply" onClick={() => this.checkDiscountCode()}/>
                          </React.Fragment>
                        )
                      }
                      
                      <input type="button" className="primary-button pay-button" value="Pay Now" onClick={() => this.continueToPayment()}/>
                    </div>
                  </Grid>
                </Grid>
              </div>            
            </Container>
            <Container maxWidth="md" className="terms-wrapper">
              <div className="terms">
                <Checkbox
                  className="checkbox"
                  checked={this.state.checked}
                  onChange={this.handleCheckboxChange}
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
                <span>I Agree with the 
                <a className="terms-link" onClick={this.openServiceTerms}>
                  Service Conditions
                </a>
                , the 
                <a className="terms-link" onClick={this.openGeneralTerms}>
                  General Terms and Conditions
                </a>
                and the 
                <a className="terms-link" target="_blank" href="https://www.simber.co.uk/terms/policy">Privacy Policy</a>
                <span className="required">*</span>
                </span>
              </div>
              <div className="terms">
                <Checkbox
                  className="checkbox"
                  checked={this.state.eighteenChecked}
                  onChange={this.handleEighteenCheckboxChange}
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
                <span> I am at least 18 years old
                <span className="required"> *</span>
                </span>
              </div>
            </Container>
          </Container>
        </main>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="error">
            {this.state.errorMsg}
          </Alert>
        </Snackbar>
        <Dialog
          className="terms-dialog"
          open={this.state.openGeneralTermsModal}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <GeneralTermsComponent />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this.closeGeneralTerms}
              className="secondary-button dialog-button"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="terms-dialog"
          open={this.state.openServiceTermsModal}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ServiceTermsComponent />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this.closeServiceTerms}
              className="secondary-button dialog-button"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
        <FooterComponent />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<SummaryActionTypes>): DispatchedProps =>
  bindActionCreators(
    {
      clearState
    },
    dispatch
  );

const mapStateToProps = (state: RootState): MappedProps => {
  return {
    data: {...state.summary, date: new Date(state.summary.date)},
    email: state.user.email,
    name: state.user.name,
    userId: state.user.userId,
    phone: state.user.phone,
    token: state.user.token
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryPage)

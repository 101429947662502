export const mainEmail = "bookings@simber.co.uk"
export const phone = "+44 (0) 203 983 5383"
export const lostAndFoundEmail = "lostandfound@simber.co.uk"

export function redirectToFb(){
    window.open("https://www.facebook.com/simberlondon");
}

export function redirectToInsta(){
    window.open("https://www.instagram.com/simberlondon");
}
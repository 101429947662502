export enum ContactReasons {
    Event = 0,
    Wedding = 1,
    Private = 2,
    CruisePortTransfer = 3,
    Other = 4,
    Corporate = 5,
    LostAndFound = 6,
    Airport = 7,
    LongDistance = 8,
    LongTerm = 9,
    SpecialCars = 10
}

export interface ContactUsDto {
    name: string;
    email: string;
    contactNumber: string;
    message: string;
    contactReason: ContactReasons;
    bookingNumber?: string;
}
import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import './GeneralTermsPage.scss';
import HeaderComponent from '../HeaderComponent';
import GeneralTermsComponent from './GeneralTermsComponent'
import MetaTags from 'react-meta-tags';

export default function GeneralTermsPage() {
    return (
        <main className="wrapper">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services General Terms and Conditions" />
                <title>General Terms & Conditions | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/terms/general" />
            </MetaTags>
            <HeaderComponent />
            <Container className="general-terms" component="main" maxWidth="md">
                <GeneralTermsComponent />
            </Container>
            <FooterComponent />
        </main>
    )
}
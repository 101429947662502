import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import MetaTags from "react-meta-tags";
import StructuredBreadcrumb, { IBreadcrumb } from "../BreadCrumbs";
import { AccordionComponent } from "../ServicesPages/AccordionComponent";
import "./RollsRoycePage.scss";

export default function RollsRoycePage() {
    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Vehicles",
          url: "https://www.simber.co.uk/vehicles",
        },
        {
          description: "Mercedes V-class",
          url: "https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire",
        },
      ];
    
      return (
        <div className="service-background-wrapper">
          <MetaTags>
            <meta
              name="description"
              content="Experience top chauffeur services in London by hiring a Rolls Royce driver, for elegant and comfortable travelling experience at its finest."
            />
            <title>Luxury Rolls Royce Chauffeur Car</title>
            <link
              rel="canonical"
              href={"https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire"}
            />
          </MetaTags>
          <div className="service-page-wrapper">
            <div className="content-wrapper"></div>
            <HeaderComponent />
            <main>
              <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
              <div className="container">
                <div className="service-picture ghost-wrapper"></div>
                <img
                  onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                  className="top-img"
                  src={require("../../images/pictograms/top_arrow.png")}
                />
                <div className="content">
                  <h1><span>Unmatched Luxury with Rolls Royce Chauffeur Service in London</span></h1>
                  <p>It's time to experience the best of the Simber's chauffeured transportation in London. Discover our Rolls Royce chauffeur service and choose the highest level of luxury and sophistication for any occasion.</p>
                  <p>Rolls Royce Ghost is a timeless vehicle with an iconic design ensuring a stylish and unforgettable arrival. The interior features comfortable seats, ample legroom, and advanced noise-cancellation technology.</p>
                  <h2>Why Choose a Rolls Royce Chauffeur Service?</h2>
                  <p>Simber offers bespoke Rolls Royce hire packages in London, and here are a few reasons to choose some of them:</p>
                  <ul className="dot-list">
                    <li><strong>Elegant</strong> - Experience the beauty of Rolls Royce Ghost as you glide through London's streets</li>
                    <li><strong>Comfort</strong> - The interior provides enough comfort, whether you just took off a plane or you woke up in some London hotel</li>
                    <li><strong>Professional</strong> - Our chauffeurs are discreet and focused on a seamless journey</li>
                  </ul>
                  <p>Why wait? Experience the ultimate luxury with our Rolls Royce hire!</p>
                  <h3>Perfect for Every Occasion</h3>
                  <p>What should Rolls Royce chauffeur services be used for? Here are a few great occasions:</p>
                  <ul className="dot-list">
                    <li>Corporate events leave a powerful first impression</li>
                    <li>Weddings to arrive in style and luxury</li>
                    <li>Celebrating milestones and special events</li>
                    <li>Personalized experiences around London, like daily tours or business meetings</li>
                  </ul>
                  <h3>Bespoke Service Just For You</h3>
                  <p>Simber believes in personalization, so our Rolls Royce hire package in London includes:</p>
                  <ul className="dot-list">
                    <li><strong>Flexible Itineraries</strong> - We tailor our service according to your preferences and expectations</li>
                    <li><strong>Professional Chauffeurs</strong> - Our chauffeurs will extend your experience with their knowledge of London and the area</li>
                    <li><strong>Additional Amenities</strong> - An optional way to add refreshments or personalized music selections (if offered)</li>
                  </ul>
                  <p>Choose your Rolls Royce hire by Simber. Ask for a quote today.</p>
                  <p>
                    <button
                      className="primary-button center"
                      onClick={() =>
                        (window.location.href = "https://www.simber.co.uk/contact")
                      }
                    >
                      Contact us
                    </button>
                  </p>
                  <div className="devider"></div>
                  <h2 className="faq-section-header">FAQs</h2>
                  <div
                    className="faq-wrapper"
                    itemScope
                    itemType="https://schema.org/FAQPage"
                  >
                    <AccordionComponent
                      question="What is the cost of a Rolls Royce chauffeur service?"
                      answer="The cost of a Rolls Royce chauffeur service depends on the duration of your hire, your desired itinerary, and any additional amenities you may choose. Please contact us for a personalized quote."
                    />
                    <AccordionComponent
                      question="What if my flight gets delayed?"
                      answer="We understand that unexpected delays can occur. We will monitor your flight information and adjust your pick-up time accordingly."
                    />
                    <AccordionComponent
                      question="Do your chauffeurs have local knowledge?"
                      answer="Yes, our chauffeurs are experienced professionals with extensive knowledge of London. They can provide recommendations for restaurants, attractions, or any other services you may need."
                    />
                  </div>
                </div>
              </div>
            </main>
            <FooterComponent />
          </div>
        </div>
      );
}
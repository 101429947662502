import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import'./LongDistancePage.scss';
import $ from 'jquery';
import { AccordionComponent } from './AccordionComponent';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function LongDistancePage(){

    useEffect(() => {
        $(document).ready(function () {

            $('.fadeHover').hover(
                function () {
                    $('.fadeHover').not(this).fadeTo('fast', 0.3);
                },
                function () {
                    $('.fadeHover').fadeTo('fast', 1);
                }
            );
        });
    })

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Long Distance Chauffeur Service",
          url: "https://www.simber.co.uk/services/long-distance-chauffeur"
        },
    ]

    const scrollToContent = () => {
        var height = window.innerHeight
    
        window.scroll({top: height, behavior: 'smooth'});
    }

    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Simber ensures that your long distance journey starts and ends in a comfortable and luxurious setting with everything you need until reaching your destination." />
                <title>Reliable Long Distance Chauffeur Services | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/long-distance-chauffeur" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                    <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="lg" className="container">
                        <div className="longdistance-wrapper service-picture"></div>
                        <div className="content">
                            <img className="scroll-to-content-arrow" src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                            <h1><span>Long Distance Chauffeur Service London</span></h1>
                            <p>Enjoy a luxurious and comfortable long-distance journey with a professional chauffeur who will ensure your safety and comfort throughout. Travel in style, even when it takes hours to arrive at your destination.</p>
                            <h2>Travelling Long Distance: Most Comfortable, Reliable, and Safest Chauffeur Service in London</h2>
                            <p>Start the long-distance ride in a luxurious and comfortable setting. Choose the best car for your long-distance driver service and enjoy the ultimate comfort and reliability. Our trusted long distance <a href="https://www.simber.co.uk/">chauffeur services</a> will make sure you have everything you need during your journey, including discretion and confidentiality.</p>
                            <p><button className="primary-button center" onClick={() => window.location.href="https://www.simber.co.uk/contact"}>Contact us</button></p>
                            <p>Simber takes you on a door-to-door journey, but you can focus on your business errands in the meantime, relax by watching a film, or listen to music. Our long-distance chauffeur company lets you enjoy the British sights through the car window, making the long hours short and pleasant.</p>
                            <p>Planning a long-distance trip within the UK or across Europe to France, Belgium, or beyond?</p>
                            <p>We got your back! We offer reliable and comfortable long-distance car services, taking the stress out of the journey. Whether you need a national long-distance chauffeur or a European chauffeur service, it's simple to hire us, no matter the destination.</p>
                            <p>You simply need to email us your long-distance private car service details, including pickup and drop-off spots, dates, and preferred vehicle type. We'll handle all the logistics for you, so you only have to enjoy a smooth and safe ride.</p>
                            <p>Regardless of the destination, we will get you there.</p>
                            <div className="picture-section big">
                                <img alt="Photo of the passenger space of an MPV." src={require("../../images/servicepages/longdistance_1.webp")}/>
                            </div>
                            <h3>Our Long-Distance London Chauffeur Service Includes</h3>
                            <div className="row-space-around longdistance-list">
                                <ul>
                                    <li>Picking you up from your desired location with our long distance car service</li>
                                    <li>Assisting you with luggage or equipment</li>
                                    <li>Hassle-free transition via other cities or countries</li>
                                </ul>
                                <ul>
                                    <li>Breaks and stops according to your needs or itinerary during your long distance car travel</li>
                                    <li>Comfortable ride with or without a pleasant conversation with the chauffeur (as you prefer)</li>
                                    <li>Safe arrival at the final destination with Simber’s long distance ride service</li>
                                </ul>
                            </div>
                            <p>During long journeys, it is vital that you feel comfortable, relaxed and arrive safely and on time. With our patient and experienced long distance chauffeurs, your comfort and safety are our top priority. </p>
                            <p>If the journey is too long and you need to stay overnight at a hotel on the way to your final destination, your reliable chauffeur will be accommodated nearby so you can continue the distance trip without falling behind your schedule.</p>
                            <p>To view the full range of any chauffeured vehicle please visit the <a href="https://www.simber.co.uk/vehicles" rel="nofollow">Vehicles Page</a> </p>
                            <div className="picture-section">
                                <img alt="Photo of the back seat of a premium vehicle, confortable for long distance rides." src={require("../../images/servicepages/longdistance_2.webp")}/>
                                <div className="content">
                                    <h3>Chauffeur Services for London Tourists</h3>
                                    <p>If you are travelling to London and would also like to explore the United Kingdom, our professional, knowledgeable and friendly Simber chauffeurs can take you and your family anywhere you want, taking stops on the way for sightseeing, lunches or taking beautiful photos. With Simber, you get a personalised long distance chauffeur service uk to and past historic landmarks. It is our aim that you, your family and friends enjoy your visit to the maximum. </p>
                                    <p>A number of clients require long distance chauffeur service for their pets, therefore if  you want to avoid the long restrictions process at airports, <a href="https://www.simber.co.uk/contact" rel="nofollow">contact Simber</a> to organise your transport so that you and your pet can enjoy the ride you deserve in one of our premium comfortable long distance cars.</p>
                                </div>
                            </div>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Long Distance Chauffeur FAQ</h2>                    
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent 
                                    question="I’m planning to travel to Europe, can you provide a chauffeured vehicle?" 
                                    answer="Our long distance chauffeur company Simber would be delighted to assist any of our clients with National and European chauffeured services. We are able to work with you and your itinerary, to coordinate bookings for the Eurotunnel le Shuttle or Ferry across the Channel as well as organise hotels for your chauffeur so that they are always in close proximity. Simber also offers you the possibility that the same chauffeur drives you until your return to the UK. Don't hesitate to contact us, to discuss your requirements."
                                />
                                <AccordionComponent 
                                    question="Can you provide long distance chauffeur services for my family and our pet?" 
                                    answer="Absolutely. It would be an honour to drive you and your beloved companions on your car service for a long distance journey in a spacious Mercedes V class. At Simber, we offer pet friendly chauffeur services, which are reliable and professional. If in the event you require for your pet to go on a distance trip unaccompanied please let us know. Our comfort chauffeurs are fully licensed and insured to carry animals in their long distance vehicles so you can rest assured that the safety and wellbeing of your pet is our priority at all times. "
                                />
                                <AccordionComponent 
                                    question="Which vehicles can I choose from?" 
                                    answer="Clients can choose from a number of long distance vehicles which are listed on the Vehicles page including the amount of luggage each chauffeured vehicle can fit, The vehicles include the executive Mercedes E Class, Luxury Mercedes S-Class and the extremely comfortable Mercedes V-Class. On request, our long distance chauffeur company Simber can arrange a Range Rover Vogue. Please contact us to request this chauffeured vehicle."
                                />
                                <AccordionComponent 
                                    question="How old are your vehicles? " 
                                    answer="We operate with an updated fleet and it doesn't matter which long distance chauffeur car you choose, you should know that they are usually less than 4 years old."
                                />
                                <AccordionComponent 
                                    question="Do you organise the whole long-distance journey? " 
                                    answer="Yes, we do. Are you asking the question: Is there any long distance car service near me?’’. We can be at your service. We are also able to work with you according to your requirements to chauffeur plan the long distance journey with our long distance services. For safety reasons, be aware that the chauffeur will be required to take breaks during the journey. However, if there is somewhere special you would like to stop to take a photo or have lunch please don't hesitate to let us or your chauffeur know so that we can include your wish into the chauffeur plan.  "
                                />
                                <AccordionComponent 
                                    question="Do you provide car seats for children? " 
                                    answer="We provide car seats for children such as baby seats, child seats and booster seats. Prior to confirming your booking, please, add your child's age or the type of child seat required. "
                                />
                                <AccordionComponent 
                                    question="How much luggage will fit in the vehicle ? " 
                                    answer="The amount of luggage will depend on the vehicle type, the Mercedes E-Class or similar saloons can fit two large suitcases, the Mercedes S-Class can fit one large suitcase and two small carry-ons, the Mercedes V-Class (MPV) can hold five large suitcases and four/five small carry-ons. Any of our long distance private car service has different space available. "
                                />
                                <AccordionComponent 
                                    question="Do your vehicles have on-board entertainment?" 
                                    answer="All of our cars for long distance offering chauffeured services are equipped with bluetooth, radio and CD players. If you wish to have your favourite radio station on, or make use of the bluetooth to listen to your playlist please let your reliable chauffeur know. The majority of our long distance car services also have DVD players and Wifi. If you have any special requests, please make sure to let us know in the booking form of our long distance chauffeur service uk."
                                />
                                <AccordionComponent 
                                    question="Will I be able to eat and drink in the car?" 
                                    answer="Refreshments such as water and mints are provided as standard. Our comfort chauffeurs are all very friendly and welcoming and we understand that long distance journeys are no fun without a few snacks. However, please do note that we do not permit eating meals or drinking alcohol within our vehicles."
                                />
                                <AccordionComponent 
                                    question="Can I smoke in the car?" 
                                    answer="All of the chauffeured car services are smoke-free, we will, however, happily accommodate any smoke breaks as requested. Our long distance limo service is open to your requirements. "
                                />
                                <AccordionComponent 
                                    question="How much does a chauffeur service cost?" 
                                    answer="The cost depends on the distance, type of vehicle,service type, and additional services like waiting time or special demands. Request a quote to get a tailored price matching your preferences."
                                />
                                <AccordionComponent 
                                    question="What is chauffeur service?" 
                                    answer="A chauffeur service provides a professional driver who transports you in a luxurious vehicle. They cater to your needs, ensuring a comfortable and efficient journey."
                                />
                                <AccordionComponent 
                                    question="How does a private chauffeur work?" 
                                    answer="Private chauffeur companies are employed directly by individuals or families. They handle transportation needs, schedule appointments, and maintain the vehicle."
                                />
                                <AccordionComponent 
                                    question="How much do chauffeurs charge per mile?" 
                                    answer="It's not a common practice to charge by mile. Most chauffeur companies charge hourly or depending on the package. Ask for a quote if you have a specific requirement."
                                />
                                <AccordionComponent 
                                    question="How much does it cost to hire a driver in the UK?" 
                                    answer="There is no fixed price, as it depends on the specific requirements and preferences the client may have, as well as the vehicle, distance, and additional assistance provided."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}
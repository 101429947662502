import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import ServiceTermsComponent from './ServiceTermsComponent';
import './ServiceTermsPage.scss';
import MetaTags from 'react-meta-tags';

export default function ServiceTermsPage() {
    return(
        <main className="wrapper">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services Service Terms and Conditions" />
                <title>Service Terms & Conditions | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/terms/service" />
            </MetaTags>
            <HeaderComponent />
            <Container maxWidth="md" className="service-terms">
                <ServiceTermsComponent />
            </Container>
            <FooterComponent />
        </main>
    )
}
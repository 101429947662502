import { SAVE_STATE, SAVE_TOKEN, CLEAR_STATE, CLEAR_USER_STATE, ACCEPT_COOKIE } from "./actionTypes";
import { SummaryState } from "./reducers/summaryReducer";
import UserDto from "../dtos/UserDto"

export interface ISaveTokenActionType {
    type: string,
    payload?: UserDto
}

export interface ISaveSummaryStateActionType {
    type: string,
    payload?: SummaryState
}

export interface IClearSummaryStateActionType {
    type: string
}

export interface IClearUserStateActionType {
    type: string
}

export interface IAcceptCookieActionType {
    type: string
}

export const saveToken = (content: UserDto): ISaveTokenActionType => {
    return {
        type: SAVE_TOKEN,
        payload: content
    }
};

export const acceptCookie = (): IAcceptCookieActionType => {
    return {
        type: ACCEPT_COOKIE
    }
}

export const saveState = (content: SummaryState): ISaveSummaryStateActionType => {
    return{
        type: SAVE_STATE,
        payload: {
            ...content
        }
    }
};

export const clearState = (): IClearSummaryStateActionType => {
    return {
        type: CLEAR_STATE
    }
}

export const clearUserState = (): IClearUserStateActionType => {
    return {
        type: CLEAR_USER_STATE
    }
}

export type UserActionTypes = 
    ISaveTokenActionType &
    IClearUserStateActionType;
export type SummaryActionTypes =
    ISaveSummaryStateActionType &
    IClearSummaryStateActionType;
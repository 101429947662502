import { SAVE_TOKEN, CLEAR_USER_STATE, ACCEPT_COOKIE } from "../actionTypes";
import { UserActionTypes } from "../actions";
import { axiosInstance } from "../../config/Axios.config";

export interface IUserState{
  token: string;
  email: string;
  userId: string;
  name: string;
  phone: string;
  cookie: boolean;
}

const clearState: IUserState = {
  token: "",
  email: "",
  userId: "",
  name: "",
  phone: "",
  cookie: true
}

const initialState: IUserState = {
   token: localStorage.getItem("token") ?? "",
   email: localStorage.getItem("email") ?? "",
   userId: localStorage.getItem("userId") ?? "",
   name: localStorage.getItem("name") ?? "",
   phone: localStorage.getItem("phone") ?? "",
   cookie: localStorage.getItem("cookie") === null
};
  
export default function(state = initialState, action: UserActionTypes): IUserState {
  switch (action.type) {
    case SAVE_TOKEN: {
        localStorage.setItem("token", action.payload?.token ?? "");
        localStorage.setItem("email", action.payload?.email ?? "");
        localStorage.setItem("userId", action.payload?.userId ?? "");
        localStorage.setItem("name", action.payload?.name ?? "");
        localStorage.setItem("phone", action.payload?.phone ?? "");
        return{
            ...state,
            token: action.payload?.token ?? "",
            email: action.payload?.email ?? "",
            userId: action.payload?.userId ?? "",
            name: action.payload?.name ?? "",
            phone: action.payload?.phone ?? ""
        }
    }
    case CLEAR_USER_STATE: {
      return clearState;
    }
    case ACCEPT_COOKIE: {
      localStorage.setItem("cookie", "accepted");
      return {
        ...state,
        cookie: false
      }
    }
    default: {
      if(initialState.token !== "")
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + initialState.token;
      return state;
    }
  }
}
  
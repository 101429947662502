import { Container } from '@material-ui/core';
import React, { useState } from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import './WeddingChauffeuringPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';
import SearchComponent from '../SearchComponent';

export default function WeddingChauffeuringPage(){

    const [bookingOpen, setBookingOpen] = useState(false);

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Wedding Chauffeur Services",
          url: "https://www.simber.co.uk/services/wedding-chauffeur"
        },
    ]

    const scrollToContent = () => {
        var height = window.innerHeight
    
        window.scroll({top: height, behavior: 'smooth'});
    }
    
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Make your special day even more memorable with our premium wedding chauffeur services in London and across the UK" />
                <title>Premium Wedding Chauffeur Service in London and Across the UK</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/wedding-chauffeur" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="lg" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="wedding-wrapper service-picture"></div>
                        <div className="content">
                            <img className="scroll-to-content-arrow" src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                            <h1><span>Wedding Chauffeur Services</span></h1>
                            <h2>Relish Your Wedding with An Exceptional Wedding <br /> Chauffeur Service for You, Your Family and Friends </h2>
                            <p>Let <a href="https://www.simber.co.uk/">Simber</a> take care of your chauffeur wedding transportation needs with utter luxury and satisfaction. Our exquisite fleet of cars will add a special flair or refinement to your most special occasion while our kindest and finest Chauffeurs will ensure you glide smoothly and effortlessly throughout the day.</p>
                            <p>Lets <a href="https://www.simber.co.uk/contact" rel="nofollow">discuss</a> details</p>
                            <h3>Our Wedding Chauffeur London Services include </h3>
                            <ul>
                                <li>A trusting, well presented wedding chauffeur</li>
                                <li>Smooth and safe ride from and to any destination with our finest chauffeurs</li>
                                <li>A luxury chauffeured wedding car like Mercedes S-Class, Mercedes V-Class, or Mercedes E-Class decorated with coloured ribbons of your choice</li>
                                <li>A wedding service with a complimentary champagne on board for the bride and groom.</li>
                                <li>Assistance with luggage of bride and groom for the honeymoon.</li>
                            </ul>
                            <p><button className="primary-button center" onClick={() => setBookingOpen(!bookingOpen)}>Book Now</button></p>
                            <div className="devider"></div>
                            <h3>Hiring More than One Wedding Car </h3>
                            <p>For your extended family or friends, we offer you the possibility to hire multiple chauffeur driven vehicles. Be sure to specify in the booking form how many passengers are to be using our chauffeur driven cars so that we can  provide you with a lifetime-worth memory of exceptional chauffeured vehicles and services.</p>
                            <div className="devider"></div>
                            <h3>Wedding Chauffeured Cars in London </h3>
                            <p>Our <a href="https://www.simber.co.uk/vehicles" rel="nofollow">fleet of cars </a> include the breathtaking modern wedding cars such as Mercedes S Class perfect for the bride and groom, the stunning Mercedes E class as well as the spacious Mercedes V class that can comfortably transport up to 7 passengers and make your valued guests get to the venue in time before your arrival.</p>
                            <p>If you would like to make an even bigger impression on your wedding day and feel like a celebrity, Simber offers the elegant Range Rover Autobiography Long-Wheel as well as the extravagant Rolls Royce Ghost. That’s why our chauffeur driven wedding cars London wide are the perfect choice.</p>
                            <div className="picture-section big">
                                <img alt="Close-up of a newly wed couple riding in high class car." src={require("../../images/servicepages/wedding_1.webp")}/>
                            </div>
                            <p>In the event that you require groups of 8 or more to travel in the same wedding car London, Simber can offer chauffeur driven executive minibuses of all sizes to suit your requirements. </p>
                            <p>As you prepare to walk down the aisle with your life-time partner, let Simber take you and your loved ones to the celebration of your new life, in style, luxury and comfort with our chauffeur driven wedding cars.</p>
                            <p>With Simber, your wedding car chauffeur transport from your home to the church and then to the reception venue will be smooth, worry-free and always on time. Let us handle any last-minute scheduling requests should they arise with your guests and their transportation needs by hiring us to look over the situation during the whole day. Our car hire wedding provides the whole package!</p>
                            <p><button className="primary-button center" onClick={() => setBookingOpen(!bookingOpen)}>Book Now</button></p>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Wedding Chauffeur FAQ</h2>                    
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="Do you offer alternative cars for the wedding reception?" 
                                    answer="Our fleet is described on our vehicles page, however, we understand that there may be some specific wedding cars for hire you would like to request for your wedding reception, hence, please contact us so we can work with our partners to source this vehicle for you."/>
                                <AccordionComponent
                                    question="Are wedding cars limited in the amount of trips they make for the wedding party?" 
                                    answer="Wedding cars and wedding drivers are not limited to the amount of trips they make as they are booked on a time basis, they are therefore at your disposal throughout the duration of your wedding. This time can be used for taking photos of your special day, transporting family or friends, or even asking your chauffeur to run a last minute errand. Should the vehicle/s be required for additional time in general this is no problem, any excess time requested will be charged at the hourly rate which is divided in 15 minute intervals."
                                />
                                <AccordionComponent
                                    question="Can I book several vehicles for my wedding guests?" 
                                    answer="Yes you can, feel free to contact us to discuss your requirements in detail. We are always more than happy to assist you any way we can. Our wedding chauffeurs will be happy to provide the best possible car service for wedding."
                                />
                                <AccordionComponent
                                    question="Can I choose the colour of the ribbons to go on the vehicles?" 
                                    answer="Yes, of course.  Please, do let us know when you confirm your booking the colour of the ribbons you would like on the vehicles or exquisite bridal cars as it may be a colour that we need to order prior to your special day."
                                />
                                <AccordionComponent
                                    question="We are having an amazing time, are the chauffeurs able to stay for a few extra hours on our special day?" 
                                    answer="Yes, they can, if the vehicle/s are required for an additional amount of time, there will be no problem to keep them for longer. However, any excess time requested will be charged at the hourly rate which is divided in 15 minute intervals. Nevertheless, your luxury chauffeur driven wedding car London will be at your service."
                                />
                                <AccordionComponent
                                    question="I would love to recommend Simber, how do I do that?" 
                                    answer="You can share some photos of your special day on social media or provide our contact details to your family, friends or colleagues. We’d also love a review from you sharing your experience with us from your wedding chauffeur car hire."
                                />
                            </div>
                        </div>                  
                    </Container>
                    <SearchComponent isOpen={bookingOpen} close={() => setBookingOpen(false)}/>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}
import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../config/Axios.config';
import './ProfileData.scss';
import ProfileDataDto from '../../dtos/ProfileDataDto';
import MetaTags from 'react-meta-tags';

export default function ProfileData() {

    const [edit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [updated, setUpdated] = useState(false);
    const [init, setInit] = useState(true);
    const [error, setError] = useState("");

    const setData = (data: ProfileDataDto) => {
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
    }

    useEffect(() => {
        if(init) {
            axiosInstance.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
            axiosInstance.get("profile/data")
                .then(res => {
                    setData(res.data);
                })
                .catch(err => {
                    if (err.response?.status === 404) {
                        setError("Unknown error occured");
                    }
                    else {
                        if(err.response?.status === 419) {
                            localStorage.clear();
                            window.location.href = "/";
                            return;
                        }
                        if (err.response?.data !== undefined) {
                        setError(err.response.data.message);
                        }
                        else {
                        setError("Cannot reach server");
                        }
                    }
                })
            setInit(false);
        }
    })

    const tryUpdateProfileData = ()  => {
        if(address === "" || email === "" || phone === "" || name === "") {
            setError("Every field must have a value");
            return;
        }
        if (email.length === 0) {
            setError("Email address cannot be empty.");
            return;
        } else {
            if (!email.includes("@")) {
                setError("Email address must contain '@' character.");
                return;            
            } else {
                var reg = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
                if (!reg.test(email)) {
                    setError("Email address not valid.")
                    return;
                }
            }
        }

        var data: ProfileDataDto = {
            address: address,
            email: email,
            name: name,
            phone: phone
        }
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        axiosInstance.post("profile/data", data)
            .then(res => {
                setUpdated(true);
                setEdit(false);
            })
            .catch(err => {
                if (err.response?.status === 404) {
                    setError("Unknown error occured");
                }
                else {
                    if(err.response?.status === 419) {
                        localStorage.clear();
                        window.location.href = "/";
                        return;
                      }
                    if (err.response?.data !== undefined) {
                    setError(err.response.data.message);
                    }
                    else {
                    setError("Cannot reach server");
                    }
                }
            })
    }

    if(edit) {
        return (
            <div className="profile-tab">
                <input
                    type="text"
                    className="text-input"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {setName(e.currentTarget.value)}}
                />
                <input
                    type="text"
                    className="text-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {setEmail(e.currentTarget.value)}}
                />
                <input
                    type="text"
                    className="text-input"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => {setPhone(e.currentTarget.value)}}
                />
                <input
                    type="text"
                    className="text-input"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => {setAddress(e.currentTarget.value)}}
                />
                <button className="edit-button primary-button" onClick={() => tryUpdateProfileData()}>Update</button>
                <Snackbar open={error !== ""}>
                    <Alert onClose={() => setError("")} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                </div>
        )
    }
    else {
        return (
            <div className="profile-tab">
                <MetaTags>
                    <link rel="canonical" href="https://www.simber.co.uk/profile/data" />
                </MetaTags>
                {updated && <div className="updated-text"><img src={require("../../images/pictograms/tick_circle_white.png")} /><span>Your information has been updated.</span></div> }
                <div className="profile-row">
                    <span>Full Name:</span>
                    <span className="data">{name}</span>
                </div>
                <div className="profile-row">
                    <span>Email:</span>
                    <span className="data">{email}</span>
                </div>
                <div className="profile-row">
                    <span>Phone:</span>
                    <span className="data">{phone}</span>
                </div>
                <div className="profile-row">
                    <span>Address:</span>
                    <span className="data">{address}</span>
                </div>
                <button className="edit-button primary-button" onClick={() => setEdit(true)}>Edit</button>
                <Snackbar open={error !== ""}>
                    <Alert onClose={() => setError("")} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}
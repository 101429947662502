import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useState } from 'react';
import './AccordionComponent.scss';

export interface IAccordionProps {
    question: string;
    answer: string;
}

export function AccordionComponent(props: IAccordionProps) {
    const [expanded, setExpanded] = useState(false);
    return (
        <Accordion className="faq-accordion" itemScope itemProp="mainEntity" itemType="https://schema.org/Question" expanded={expanded} onChange={(e, exp) => setExpanded(exp)}>
            <AccordionSummary expandIcon={<img src={require('../../images/servicepages/accordion_arrow' + (expanded ? '_open.png' : '.png'))}/>}>
                <span className="faq-header" itemProp="name">{props.question}</span>
            </AccordionSummary>
            <AccordionDetails className="faq-details" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                <span className="a-tag">A:</span>
                <span itemProp="text">
                    {props.answer.split("\n").map((i,key) => {
                        return <div className={key > 0 ? "newline" : ""} key={key}>{i}</div>;
                    })}
                </span>
            </AccordionDetails>
        </Accordion>
    )
}
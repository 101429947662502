import React from 'react';
import { Container } from '@material-ui/core';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import MetaTags from 'react-meta-tags';
import './HotelTransportPage.scss'
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function HotelTransportPage() {
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
            description: "Hotel Transfers",
            url: "https://www.simber.co.uk/services/strategic-partnerships-hotel-transport"
        },
    ]

    const scrollToContent = () => {
        var height = window.innerHeight
    
        window.scroll({top: height, behavior: 'smooth'});
    }

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Simber offers a strategic partnership to hoteliers in order to extend their services for hotel guests related to transfers and private tours across London." />
                <title>Become Simber's Partner and Expand Your Outstanding Services | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent />
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="lg" className="container">
                        <div className="hotel-wrapper service-picture"></div>
                        <div className="content">
                            <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                            <img className="scroll-to-content-arrow" src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                            <h1><span>Strategic Partnerships: Collaborative Transport Solutions with Simber Chauffeur Services</span></h1>
                            <p>Simber understands the need and importance of reliable and seamless ground transportation, especially in busy cities like London. That's why we offer collaborative transport solutions through our strategic partnerships programs, for you and your valued clients.</p>
                            <h2>Simber: Hassle-Free Travel for Guests, <br /> Clients & Staff</h2>
                            <p>Business travel needs have changed in recent years, demanding a completely new approach to ground transportation. Simber fills that gap with collaborative solutions. </p>
                            <p>How?</p>
                            <p>We partner with corporations, businesses of any size, and hotels to provide every traveller with a reliable travel experience. This ensures everyone arrives at their destination on time, relaxed, and ready to focus on what matters most – their business.</p>
                            <h3>Strategic Partnerships with Hotels</h3>
                            <p>Partner with Simber because your guests deserve the best traveling service. We also offer partnerships with hotels to help you accommodate the guests better and have our chauffeurs available for rides to and from events, on tours, or from and to airports - as well as seaports across the UK.</p>
                            <p>Here's what you get:</p>
                            <ul>
                                <li>Branded vehicle</li>
                                <li>Attractive daily and hourly rates</li>
                                <li>Full support during the private tours</li>
                                <li>Complementary water and refreshments on board</li>
                                <li>Well-presented chauffeurs and vehicles</li>
                            </ul>
                            <p>By partnering with Simber, you can extend your commitment to exceptional service beyond the hotel walls.</p>
                            <p><a className="primary-button center" href="https://www.simber.co.uk/contact">Contact us</a></p>
                            <div className='devider'></div>
                            <h3>Strategic Partnerships with Corporates</h3>
                            <p>A strategic partnership with the right chauffeur company will enhance your corporate image and employee and client satisfaction. Partner with Simber to:</p>
                            <ul>
                                <li>Save time and resources with our streamlined booking process</li>
                                <li>Enjoy cost-effective solutions and special service packages </li>
                                <li>Represent a professional image with professionally maintained vehicles</li>
                                <li>Boost the team morale and make them feel valued and appreciated</li>
                                <li>Guaranteed peace of mind with a partner who will exceed your expectations</li>
                                <li>Simber - Your Strategic Partner for a Successful Business</li>
                            </ul>
                            <p><a className="primary-button center" href="https://www.simber.co.uk/contact">Contact us</a></p>
                            <div className='devider'></div>
                            <h2>This is How Our Partnership <br /> Will Look Like</h2>
                            <p>Your guests, clients or staff can use our chauffeur services and the fare can be handled by you internally, whilst Simber invoices you on a weekly or monthly basis.</p>
                            <p>You will be allocated an account manager within Simber Chauffeur Services which you can have direct communication with for bookings or queries you may have.</p>
                            <p>Your guests, clients or staff will experience an elevated service, focusing on their transportation needs whilst being surrounded by chauffeurs which are attentive and professional.</p>
                            <p>As one of our partners, your team and respective staff will build a long term relationship not only with our office team but also with our chauffeurs.</p>
                            <p>We can personalize name boards to display your logo along with the guests name, when taking care of transfers from airports to their desired destination.</p>
                            <p>Please get in touch to discuss your requirements with a member of the Simber team!</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent />
            </div>
        </div>
    )
}
import { Container } from '@material-ui/core';
import React, { useState } from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import './SeaportTransfersPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';
import SearchComponent from '../SearchComponent';

export default function SeaportTransfersPage(){
    
    const [bookingOpen, setBookingOpen] = useState(false);
    
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Seaport Transfers",
          url: "https://www.simber.co.uk/services/seaport-transfers"
        },
    ]

    const scrollToContent = () => {
        var height = window.innerHeight
    
        window.scroll({top: height, behavior: 'smooth'});
    }

    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="If you are going on a cruise holiday in the UK, let Simber take care of the logistics of your seaport transfer so you can enjoy the journey worry-free. " />
                <title>Prompt and Reliable Seaport Transfers | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/seaport-transfers" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="lg" className="container">
                        <div className="seaport-wrapper service-picture"></div>
                        <div className="content">
                            <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                            <img className="scroll-to-content-arrow" src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                            <h1><span>Seaport Transfers With Luxury Chauffeur Service</span></h1>
                            <p>Arriving at some of the UK's seaports and don't know how to get to London from there? Let us offer the most convenient solution by picking you up and taking you anywhere you need. We can even bring you back to the seaport by the time you need to leave the UK. How? We offer the ultimate chauffeur-driven tours from and to the UK seaports for every traveller!</p>
                            <h2>Chauffeur Driven Transfers <br /> from and to UK Seaports </h2>
                            <p><a href="https://www.simber.co.uk/">Simber</a>  offers you the best seaport chauffeur services from and to the UK's main ports and cruise terminals. With us, you can be assured your seaport transfer will be smooth, efficient, and in complete comfort and luxury.</p>
                            <p>We know how stressful getting to and from cruise can be, so we're proud to offer top-notch service. Our chauffeurs are attentive, discrete and welcoming. We are eager to satisfy your seaport transportation needs from the moment you arrive in London to the day we drop you off at your cruise terminal or vice versa.</p>
                            <p>You can begin your cruise holiday in style and never worry about getting to the preferred destination on time.</p>
                            <p>Regardless of whether you are travelling to and from Dover, Southampton, Harwich or any other UK port, you will get to your airport terminal or hotel in utter comfort.</p>
                            <p><button className="primary-button center" onClick={() => setBookingOpen(true)}>Book Now</button></p>
                            <div className="picture-section big">
                                <img alt="Photo of a parked MPV waiting for the passengers getting of their Cruse ship."src={require("../../images/servicepages/seaport_1.webp")} />
                            </div>
                            <h2>Let Simber Handle the <br /> Logistics of your Seaport Transfer </h2>
                            <p>Our fleet of vehicles include the comfortable saloon Mercedes E-Class, luxurious Mercedes S-Class, the spacious Mercedes V-Class which will comfortably accommodate up to seven passengers with plenty of space for the ever so important luggages.</p>
                            <p>Regardless of the luxury car hire you choose, or where the journey begins, you can be assured that while you relax and enjoy the scenic ride Simber will look after every need and ensure you arrive safely, on time and in style.</p>
                            <div className="devider"></div>
                            <h2>Seaport Chauffeur Services for <br /> Your Entire London Stay</h2>
                            <p>In addition to London seaport transportation, Simber offers  short or long term chauffeur hire. This service has been created for pure benefit to you, our clients. We handle all of your transportation needs while you enjoy your holiday in or around  London, before or after your cruise. Your cruise port chauffeur won’t let you down.</p>
                            <p>A dedicated  Simber Chauffeur will be on hand for your entire stay on an ‘As Directed’ basis, welcoming you at the airport, taking you to your hotel, chauffeuring you around London to visit popular tourist destinations, restaurants, bars, shopping or running flexible errands on request. Best of all, you can combine this service with <a href="https://www.simber.co.uk/services/private-tours" rel="nofollow">booking a private tour</a> during your London stay.</p>
                            <div className="picture-section big">
                                <img alt="A large Cruise Ship passing under Tower Bridge." src={require("../../images/servicepages/seaport_2.webp")} />
                            </div>
                            <p>Our professional, attentive and discreet chauffeurs combined with a premium fleet of cars, aim to provide you with the most comfortable, luxurious and efficient service before or after your cruise. You can trust Simber to handle your transportation needs, while you relax and enjoy the holiday of a lifetime. Chauffeur services from Dover port are also available, so you won’t have to worry about anything.</p>
                            <p>With Simber, your cruise holiday starts or finishes on a high note.</p>
                            <p><button className="primary-button center" onClick={() => setBookingOpen(true)}>Book Now</button></p>
                            <div className="devider"></div>
                            <h2>UK Seaports We Cover for You</h2>
                            <p>While we're primarily focused on London, our service and expertise extends to the whole UK, on your request. To make a personalized inquiry, you can contact us with details, and we'll do our best to accommodate you, potentially picking you up or dropping you off to these seaports:</p>
                            <ul className="dot-list">
                                <li>Dover: The closest port to France. We offer transfers from London to Dover Port as part of our daily services.</li>
                                <li>Portsmouth: Another port that connects the UK with Europe's ground through France.</li>
                                <li>Southampton: England's popular port in a city known for its world-class ship departures.</li>
                                <li>Harwich: Harwich offers ferry access to continental Europe, mostly through the Netherlands.</li>
                            </ul>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Seaport Transfers FAQ</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="Will my transfer be private or shared?" 
                                    answer="Your transfer with Simber will always be private unless otherwise requested."/>
                                <AccordionComponent
                                    question="What payment method do you accept?" 
                                    answer="We accept card payments from all debit and credit cards. When booking through the website, our payment merchant Stripe will handle the transaction, alternatively you can pay via a payment link. We are also able to set up a business account for you and process all of your trips monthly, please contact us for more details."
                                />
                                <AccordionComponent
                                    question="I have a foldable wheelchair, what should I do?" 
                                    answer="Our Mercedes V-Class is able to accommodate your wheelchair as well as your luggage comfortably, our advice, though, is to make a note of this in your booking."
                                />
                                <AccordionComponent
                                    question="What is the latest time I can book?" 
                                    answer="At Simber we have previously taken bookings with one hours notice, however to guarantee availability we would recommend that you make your booking as soon as you have confirmed your travel arrangements."
                                />
                                <AccordionComponent
                                    question="Can I amend my booking?" 
                                    answer="Yes, you can amend your booking for your seaport transfer, please call or email us three hours prior to your agreed pick up / booking time, stating your booking reference as well as your requested amendments for port transfers or anything else."
                                />
                                <AccordionComponent
                                    question="I forgot something in the car, what should I do?" 
                                    answer="In the first instance our advice is to call your seaport chauffeur as he/she may be nearby and able to return your item quickly. Alternatively please call us, let us know the item you have forgotten we will liaise with the chauffeur and have it be returned to our office or your home."
                                />
                            </div>
                        </div>
                    </Container>
                    <SearchComponent isOpen={bookingOpen} close={() => setBookingOpen(false)}/>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}
import React from 'react';
import './SmallAccordionComponent.scss'

interface SmallAccordionProps {
    img: string;
    title: string;
    titleLevelH3?: boolean;
    link: string;
    text: string;
    alt: string;
}

export default function SmallAccordionComponent(props: SmallAccordionProps) {
    
    const onredirect = () => {
        window.open("/services/" + props.link, "_self");
    }

    return (
        <div className="small-accordion-card">
            <img src={require("../images/pictograms/" + props.img)} alt={props.alt}/>
            { props.titleLevelH3 ? <h3 className="small-accordion-title">{props.title}</h3> : <h2 className="small-accordion-title">{props.title}</h2>}
            <span className="small-accordion-text">{props.text}</span>
            <button className="primary-button" onClick={onredirect}>More</button>
        </div>
    )
}
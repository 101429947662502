import { Box, Select, MenuItem, FormControlLabel, Checkbox, Modal, Snackbar, Alert, Portal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { RentType, CarType, PriceData, PriceResponse } from "../dtos/PriceData";
import DatePickerComponent from "./DatePicker/DatePickerComponent";
import { axiosInstance } from "../config/Axios.config";
import { getMinDate } from "../redux/reducers/summaryReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { saveState, saveToken, UserActionTypes } from "../redux/actions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
import UserLoginDto from "../dtos/UserLoginDto";
import UserRegisterDto from "../dtos/UserRegisterDto";
import { Redirect } from "react-router";
import { Preference } from "../dtos/PaymentData";

export interface ISearchComponentProps {
    isOpen: boolean;
    close(): any;
}

interface IMappedProps {
    token: string;
}

interface AirportProps {
    onItemClick(place: string): void;
    onFocusLost(): void;
  }

function SearchComponent(props: ISearchComponentProps & IMappedProps) {
    const [date, setDate] = useState<Date>();
    const [time, setTime] = useState(4);
    const [price, setPrice] = useState<number>();
    const [premPrice, setPremPrice] = useState<number>();
    const [luxPrice, setLuxPrice] = useState<number>();
    const [mpvPrice, setMpvPrice] = useState<number>();
    const [selectOfferOpen, setSelectOfferOpen] = useState(false);
    const [originSet, setOriginSet] = useState(false);
    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [email, setEmail] = useState("");
    const [emailRe, setEmailRe] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRe, setPasswordRe] = useState("");
    const [name, setName] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showOriginList, setShowOriginList] = useState(false);
    const [spam, setSpam] = useState(false);
    const [eighteen, setEighteen] = useState(false);
    const [destinationSet, setDestinationSet] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [register, setRegister] = useState(false);
    const [guest, setGuest] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [forgotPassSuccess, setForgotPassSuccess] = useState(false);
    const [showMoreVehicles, setShowMoreVehicles] = useState(false);
    const [summary, setSummary] = useState(false);
    const [login, setLogin] = useState(false);
    const [showDestinationList, setShowDestinationList] = useState(false);
    const [showOriginByTheHourList, setshowOriginByTheHourList] = useState(false);
    const [tab, setTab] = useState<RentType>(RentType.Oneway);
    const [selectedType, setSelectedType] = useState<CarType>();
    const [contact, setContact] = useState(false);
    const [preferences, setPreferences] = useState<Preference[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [autocompleteOrigin, setAutocompleteOrigin] = useState<google.maps.places.Autocomplete>();
    const [autocompleteDestination, setAutocompleteDestination] = useState<google.maps.places.Autocomplete>();
    const [opened, setOpened] = useState(0);

    const dispatchH = useDispatch();

    function parseError(errorMsg: string) {
      var parts = [];
      if(errorMsg.length == 0)
        return
      var split = errorMsg.split("contact us");
      for(let i = 0; i < split.length; i++) {
        parts.push(<span>{split[i]} {i != (split.length-1) ? <a href="https://www.simber.co.uk/contact" >contact us</a> : ""}</span>)
      }
      return parts;
    }

    function AirportList(props: AirportProps) {
        return(
          <div className="fixed-places-list">
            <div className={opened === 0 ? "opened-list main" : "closed"}>
              {<div onClick={() => setOpened(1)}><span>Airports</span><img alt="Pictogram of a plane." src={require("../images/pictograms/airport_list_item.png")}/></div>}
              <div onClick={() => setOpened(2)}><span>Seaports</span><img alt="Pictogram of a ship." src={require("../images/pictograms/seaport_list_item.png")}/></div>
              <div onClick={() => setOpened(3)}><span>Railway Stations</span><img alt="Pictogram of a train." src={require("../images/pictograms/railway_list_item.png")}/></div>
            </div>      
            <div className={opened === 1 ? "opened-list airport-list" : "closed"} onBlur={() => { props.onFocusLost()}}>
              <div className="header" onClick={() => setOpened(0)}>
                <img className="back-img" src={require("../images/pictograms/arrow_grey.png")}/>
                <span>Airports</span>
                <img className="list-item" src={require("../images/pictograms/airport_list_item.png")}/>
              </div>
              <div onClick={() => props.onItemClick("Biggin Hill Airport, TN16 3BH")}><span>Biggin Hill Airport</span></div>
              <div onClick={() => props.onItemClick("Farnborough Airport, GU14 6XA")}><span>Farnborough Airport</span></div>
              <div onClick={() => props.onItemClick("Heathrow Airport, TW6 1QG")}><span>Heathrow Airport</span></div>
              <div onClick={() => props.onItemClick("Gatwick Airport, RH6 0NP")}><span>Gatwick Airport</span></div>
              <div onClick={() => props.onItemClick("London City Airport, E16 2PX")}><span>London City Airport</span></div>
              <div onClick={() => props.onItemClick("Luton Airport, LU2 9LY")}><span>Luton Airport</span></div>
              <div onClick={() => props.onItemClick("Oxford London Airport, OX5 1RA")}><span>Oxford London Airport</span></div>
              <div onClick={() => props.onItemClick("Stansted Airport, CM24 1QW")}><span>Stansted Airport</span></div>
              <div onClick={() => props.onItemClick("London Southend Airport, SS2 6YF")}><span>Southend Airport</span></div>
            </div>
            <div className={opened === 2 ? "opened-list seaport-list" : "closed"} onBlur={() => { props.onFocusLost()}}>
              <div className="header" onClick={() => setOpened(0)}>
                <img onClick={() => setOpened(0)} className="back-img" src={require("../images/pictograms/arrow_grey.png")}/>
                <span>Seaports</span>
                <img className="list-item" src={require("../images/pictograms/seaport_list_item.png")}/>
              </div>
              <div onClick={() => props.onItemClick("Port of Dover, CT17 9DQ")}><span>Port of Dover</span></div>
              <div onClick={() => props.onItemClick("London Port of Southampton, SO14 3QN")}><span>Port of Southampton</span></div>
              <div onClick={() => props.onItemClick("Port of Tilbury, London, RM18 8RU")}><span>Port of Tilbury, London</span></div>
              <div onClick={() => props.onItemClick("Portsmouth, PO2 8RU")}><span>Portsmouth International Port</span></div>
              <div onClick={() => props.onItemClick("Greenwich Ship Tier, SE10 9HG")}><span>Greenwich Ship Tier</span></div>
            </div>
            <div className={opened === 3 ? "opened-list railway-list" : "closed"} onBlur={() => { props.onFocusLost()}}>
              <div className="header" onClick={() => setOpened(0)}>
                <img onClick={() => setOpened(0)} className="back-img" src={require("../images/pictograms/arrow_grey.png")}/>
                <span>Railway Stations</span>
                <img className="list-item" src={require("../images/pictograms/railway_list_item.png")}/>
              </div>
              <div onClick={() => props.onItemClick("Cannon Street Station, EC4N 6AG")}><span>Cannon Street Station</span></div>
              <div onClick={() => props.onItemClick("Charing Cross Station, WC2N 5HX")}><span>Charing Cross Station</span></div>
              <div onClick={() => props.onItemClick("Fenchurch Street Station, EC3N 2NU")}><span>Fenchurch Street Station</span></div>
              <div onClick={() => props.onItemClick("Kings Cross Station, N1C 4TB")}><span>Kings Cross Station</span></div>
              <div onClick={() => props.onItemClick("Liverpool Street Station, EC2M 7QN")}><span>Liverpool Street Station</span></div>
              <div onClick={() => props.onItemClick("Paddington Station, W2 6PY")}><span>Paddington Station</span></div>
              <div onClick={() => props.onItemClick("St Pancras International Station, N1C 4TB")}><span>St Pancras Station</span></div>
              <div onClick={() => props.onItemClick("Victoria Station, SW1V 1JT")}><span>Victoria Station</span></div>
              <div onClick={() => props.onItemClick("Waterloo Station, SE1 8SR")}><span>Waterloo Station</span></div>
            </div>
          </div>
        )
    }

    const handlePrefChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      var temp = preferences;
      var index = temp.findIndex(pref => pref.Text == e.currentTarget.id);
      temp[index].Value = !temp[index].Value
      setPreferences(temp);
    }

    const initialize = () => {
        initAutocomplete();
        geolocate();
      
        var origin = document.getElementById("origin") as HTMLInputElement;
        if(origin)origin.disabled = false;
        var dest = document.getElementById("destination") as HTMLInputElement;
        if(dest)dest.disabled = false;
    }

    const  geolocate = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            var geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            var circle = new window.google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy < 100000 ? position.coords.accuracy : 100000,
            });
            autocompleteOrigin?.setBounds(circle.getBounds());        
            autocompleteDestination?.setBounds(circle.getBounds()); 
          });
        }
    }

    const initAutocomplete = () => {
        // Create the autocomplete object, restricting the search predictions to
        // geographical location types.
        var element = document.getElementById("origin") as HTMLInputElement;
        if(element)setAutocompleteOrigin(new window.google.maps.places.Autocomplete(element, {    
          componentRestrictions: {
            country: 'uk'
          }
        }));
        var dest = document.getElementById("destination") as HTMLInputElement;
        if(dest)setAutocompleteDestination(new window.google.maps.places.Autocomplete(
          dest,
          {
          componentRestrictions: {
            country: 'uk'
          }}
        ));
    }

    const handleOriginChange =(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e === undefined) {
          setOriginSet(true);
        }
        else {
          setOriginSet(false);
        }
        
        if(e?.currentTarget.value === "") {
            setShowOriginList(true);
        }
        else {
            setShowOriginList(false);
        }
    }

    const handleDestinationChange =(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e === undefined) {
            setDestinationSet(true);
        }
        else {
            setDestinationSet(false);
        }
    
        if(e?.currentTarget.value === "") {
          setShowDestinationList(true);
        }
        else {
            setShowDestinationList(false);
        }
    }

    const startAutoSession = (e: React.FocusEvent<HTMLInputElement>) => {
        if(e.currentTarget.id === "origin") {
          if(e.currentTarget.value === "") {
            setShowOriginList(true);
          }
          else {
            setShowOriginList(false);
          }
        }
        if(e.currentTarget.id === "destination") {
          if(e.currentTarget.value === "") {
            setShowDestinationList(true);
          }
          else {
            setShowDestinationList(false);
          }
        }
    }

    const clearOrigin = () => {
      (document.getElementById("origin") as HTMLInputElement).value = "";
      setOriginSet(false);
      setOrigin("");
    }
  
    const clearDestination = () => {
      (document.getElementById("destination") as HTMLInputElement).value = "";
      setDestinationSet(false);
      setDestination("");
    }

    const searchLostFocus = () => {
        setOpened(0);
        setShowOriginList(false);
        setShowDestinationList(false);
        setshowOriginByTheHourList(false);
    }

    const onAirportListItemSelected = (place: string, target: any) => {
        switch(target) {
        case "origin":
            setOriginAirport(place);
            break;
        case "destination":
            setDestinationAirport(place);
            break;
        }
        searchLostFocus();
    }
  
    const setOriginAirport = (place: string) => {
      (document.getElementById("origin") as HTMLInputElement).value = place;
      setOrigin(place);
    }

    const setDestinationAirport = (place: string) => {
      (document.getElementById("destination") as HTMLInputElement).value = place;
      setDestination(place);
    }

    const handleDateChange = (date: Date) => {
      setDate(date);
    };
    
    const showResults = () => {
        if(date === undefined) {
          setErrorMsg("Please select date & time.");
          return;
        }
    
        var origin = (document.getElementById(
          "origin"
        ) as HTMLInputElement).value;
        if(!origin) {
          setErrorMsg("Please select pickup location.");
          return;
        }
    
        var data: PriceData;
    
        if (tab === RentType.Oneway) {
          var destination = (document.getElementById(
            "destination"
          ) as HTMLInputElement).value;
    
          if(!destination) {
            setErrorMsg("Please select dropoff location.");
            return;
          }
    
          setOrigin(origin);
          setDestination(destination);
    
          data = {
            Origin: origin,
            Destination: destination,
            RentType: RentType.Oneway,
            Date: new Date(date)
          };
        } else {
          setOrigin(origin)
    
          data = {
            Origin: origin,
            Destination: "",
            RentType: RentType.ByTheHour,
            Hours: time,
            Date: new Date(date)
          };
        }

        axiosInstance
            .get<PriceResponse>("rental/price", { params: data })
            .then((res) => {
                setSelectOfferOpen(true);
                setPremPrice(res.data.premPrice);
                setLuxPrice(res.data.luxPrice);
                setMpvPrice(res.data.sevenSeaterPrice);
              }
            )
            .catch((error) => {
              if(error.status === 419) {
                localStorage.clear();
                window.location.href = "/";
                return;
              }
              if(error.response?.status === 404) {
                setErrorMsg("Unknown error occured");
                return;
              }
              if(error.response?.data !== undefined) {
                setErrorMsg( error.response.data.message);
                return;
              }          
              else{
                setErrorMsg("Cannot reach server");
              }
            });
    };

    const onSelectClick = (type: CarType) => {
        var p;
        switch (type) {
            case CarType.Luxury:
                p = luxPrice;
                break;
            case CarType.SevenSeater:
                p = mpvPrice;
                break;
            default:
                break;
        }

        setPrice(p);
        setSelectedType(type);
    };

    const onContinueToSummary = () => {
    
        if(selectedType === undefined) {
          setErrorMsg("Please select car type.");
          return;
        }
    
        if(!date) {
            setErrorMsg("Please set date.");
            return;
        }
        var z = new Date(date);
        if(z < getMinDate()){
          setErrorMsg("Online reservations cannot be made within 12 hours of the requested booking time. Please contact us on +44 203 983 5383 so we can acconodate your request")
          return;
        }
        
        if(props.token) {
            doSaveState();
            setSummary(true);
        } else {  
            setShowLogin(true);
        }
    };

    const doSaveState = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { 
        e?.preventDefault();
        dispatchH(saveState({
            date: date!,
            destination: destination,
            email: email,
            name: name,
            origin: origin,
            preferences: [],
            rentType: tab,
            price: price,
            selectedType: selectedType,
            time: time,
            phone: phone
        }));
        if(e)
            setLogin(true);
    }
    
    const closeOfferDialog = () => {
        setSelectOfferOpen(false);
        setShowMoreVehicles(false);
        setShowLogin(false);
        setSelectedType(undefined);
        setGuest(false);
        setForgotPass(false);
        setForgotPassSuccess(false);
        setRegister(false);
    }

    const doLogin = () => {
        var data: UserLoginDto = {
            Email: email,
            Password: password
        }
        axiosInstance.post("user/login", data)
            .then(response => {
                axiosInstance.defaults.headers["Authorization"] = "Bearer " + response.data
                dispatchH(saveToken(response.data));
                doSaveState();
                setSummary(true);
            })
            .catch(error => {
                if(error.status === 419) {
                    localStorage.clear();
                    window.location.href = "/";
                    return;
                }
                if (error.response?.status === 404) {
                    setErrorMsg("Unknown error occured")
                }
                else {
                    if (error.response?.data !== undefined) {
                        setErrorMsg(error.response.data.message);
                    }
                    else {
                        setErrorMsg("Cannot reach server");
                    }
                }
            })
    }

    const sendResetEmail = () => {
        axiosInstance.post("user/resetPass/getlink?email=" + email)
            .then(response => {
                setForgotPass(false);
                setForgotPassSuccess(true);
            })
            .catch(error => {
                if(error.status === 419) {
                    localStorage.clear();
                    window.location.href = "/";
                    return;
                }
                if (error.response?.status === 404) {
                    setErrorMsg("Unknown error occured");
                }
                else {
                    if (error.response?.data !== undefined) {
                        setErrorMsg(error.response.data.message);
                    }
                    else {
                        setErrorMsg("Cannot reach server");
                    }
                }
            })
    }

    const doRegister = () => {
        var errors = "";
        var reg = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
        if (!reg.test(email))
            errors += "Invalid email format.\n"
        if(name === "")
            errors += "Name is required.\n"
        if(email !== emailRe)
            errors += "Email and confirmation doesn't match.\n"
        if(password !== passwordRe)
            errors += "Password and confirmation doesn't match.\n"
        if(!eighteen)
            errors += "You must be 18 on older to use our services"
        if(errors !== "") {
            setErrorMsg(errors);
            return;
        }

        var data: UserRegisterDto = {
            Email: email,
            EmailRe: emailRe,
            Password: password,
            AllowSpam: spam,
            Name: name,
            PasswordRe: passwordRe,
            Phone: phone
        }

        axiosInstance.post("user/register", data)
            .then(response => {
                doLogin();
            })
            .catch(error => {
                if(error.status === 419) {
                    localStorage.clear();
                    window.location.href = "/";
                    return;
                }
                if(error.response?.status === 404){
                    setErrorMsg("Unknown error occured");
                }
                else {
                if(error.response?.data !== undefined){
                    setErrorMsg(error.response.data.message);
                }          
                else{
                    setErrorMsg("Cannot reach server");
                }
                }
            })
    }

    const toSummary = () => {
        if(email !== emailRe) {
            setErrorMsg("Email and confirmation does not match!");
            return;
        }
        if(name.length < 4) {
            setErrorMsg("Please provide a valid name!");
            return;
        }
        doSaveState();
        setSummary(true);
    }

    const handleClose = () => {
        setErrorMsg("");
    };

    const handleClick = (e: any) => {
        var originList = document.getElementById("origin-list");
        var destinationList = document.getElementById("destination-list");
        if(!originList?.contains(e.target) && !destinationList?.contains(e.target)) {
          searchLostFocus();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        setLoaded(true);
        return function cleanup() {
            document.removeEventListener('mousedown', handleClick, false)
        }
    }, []);

    useEffect(() => {
        if(selectedType)
            onContinueToSummary();
    }, [selectedType]);

    useEffect(() => {
        if(loaded) {
            const a = "AIzaSyBY6NfsBLbGj-OCYOmWZSgFgGnQC47Vmvo" + "&libraries=places";
            
            var scrpt = document.getElementById("googleScript");
            if(scrpt !== null )
            scrpt.remove();
            const script = document.createElement("script");
            script.id = "googleScript";
            script.src = `https://maps.googleapis.com/maps/api/js?key=${a}`;
            script.async = true;
            script.defer = true;
            script.addEventListener("load", () => {
            initialize();
            });
            document.body.appendChild(script);
        }
    }, [loaded]);

    useEffect(() => {
      document.body.style.overflowY = props.isOpen ? "hidden" : "unset";
      document.body.style.height = props.isOpen ? '100dvh' : 'unset';
    }, [props.isOpen]);

    useEffect(() => {
       // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components.
        autocompleteOrigin?.setFields(["address_component"]);
        autocompleteOrigin?.addListener("place_changed", handleOriginChange);
    }, [autocompleteOrigin])

    useEffect(() => {
        autocompleteDestination?.setFields(["address_component"]);
        autocompleteDestination?.addListener("place_changed", handleDestinationChange);
    }, [autocompleteDestination])

    if(summary)
      return <Redirect push to="/summary" />
    else
    if(contact)
      return <Redirect push to="/contact" />
    return (
      <React.Fragment>
        <Modal
                keepMounted
                open={props.isOpen}
                className='search-modal-wrapper'
                >
                <Box className="search-modal">
                    <div className="search-bar">
                    {
                    // @ts-ignore
                    0 == "1" &&
                        <div className="no-booking">
                        <span>Dear client, due to unprecedented demand our online booking system is paused until further notice. If you have an enquiry that you’d like us to assist with please <a style={{color: "#D8AF6D"}} href="https://www.simber.co.uk/contact">contact us</a>.</span>
                        </div>
                    }
                    <div className='search-bar-close' onClick={() => {props.close();}}><img src={require("../images/pictograms/close_pictogram_brown.png")}></img></div>
                    <div className="tabs">
                        <div
                        id="top"
                        className={props.isOpen ? ("tablink" + (tab == RentType.Oneway ? " active" : " closed")) : "tablink"}
                        onClick={() => {
                            setTab(RentType.Oneway);
                        }}
                        >
                        <img alt="Pictogram of a map." src={require("../images/pictograms/one_way_pictogram.png")} />
                        <span>
                            One-Way
                        </span>
                        </div>
                        <div
                        className={props.isOpen ? ("tablink" + (tab == RentType.ByTheHour ? " active" : " closed")) : "tablink"}
                        onClick={() => {
                            setTab(RentType.ByTheHour);
                        }}
                        >
                        <img alt="Pictogram of a clock." src={require("../images/pictograms/by_the_hour_pictogram.png")} />
                        <span>
                            By The Hour
                        </span>
                        </div>
                    </div>
                    <div className={"booking-card" + (tab == RentType.Oneway ? " oneway" : "")}>
                        <div className="top-row">
                        <div className="search-input-wrapper">
                            <input
                            className="search-input"
                            id="origin"
                            onChange={(e) => handleOriginChange(e)}
                            placeholder="from"
                            onFocus={(e) => startAutoSession(e)}
                            type="text"
                            />
                            {(origin || originSet) && <i onClick={clearOrigin} className="clear-input-button fas fa-times-circle"></i>}
                            {showOriginList && tab == RentType.Oneway &&
                            <div id="origin-list">
                                <AirportList onFocusLost={() => searchLostFocus()} onItemClick={(place) => onAirportListItemSelected(place,"origin")}/>
                            </div>
                            }
                        </div>
                        <div className={"search-input-wrapper" + (tab === RentType.Oneway ? "" : " hide")}>
                            <input
                            id="destination"
                            placeholder="to"
                            onChange={(e) => handleDestinationChange(e)}
                            type="text"
                            onFocus={(e) => startAutoSession(e)}
                            className="search-input"
                            />
                            {(destination || destinationSet) && <i onClick={clearDestination} className="clear-input-button fas fa-times-circle"></i>}
                            {showDestinationList && 
                            <div id="destination-list">
                                <AirportList onFocusLost={searchLostFocus} onItemClick={(place) => onAirportListItemSelected(place,"destination")}/>
                            </div>
                            }
                        </div>
                        <Select
                            value={time}
                            onChange={(e) => setTime(+e.target.value)}
                            variant='standard'
                            disableUnderline
                            name="duration"
                            id="duration"
                            className={"hour-select" + (tab === RentType.ByTheHour ? "" : " hide")}
                        >
                            <MenuItem value="4">4 hours</MenuItem>
                            <MenuItem value="5">5 hours</MenuItem>
                            <MenuItem value="6">6 hours</MenuItem>
                            <MenuItem value="7">7 hours</MenuItem>
                            <MenuItem value="8">8 hours</MenuItem>
                            <MenuItem value="9">9 hours</MenuItem>
                            <MenuItem value="10">10 hours</MenuItem>
                            <MenuItem value="11">11 hours</MenuItem>
                            <MenuItem value="12">12 hours</MenuItem>
                        </Select>
                        <DatePickerComponent dateSet={date != undefined} valueChanged={(val) => handleDateChange(val)}/>
                        </div>
                        <div className="bottom-row">
                        {tab === RentType.ByTheHour
                            ? <span className="offer-text">For Long Term Chauffeur Hire exceeding 12 hours, please contact us</span>
                            : <span className="offer-text">For long distance transfers or complex transportation requirements please contact us</span>
                        }
                        <button className="accent-button offer-button" onClick={() => setContact(true)}>Contact Us</button>
                        <input
                            className="primary-button search-button"
                            id="search-oneway"
                            type="button"
                            value="Search"
                            onClick={() => showResults()}
                        />
                        </div>
                    </div>
                    </div>
                    <Modal
                    keepMounted
                    open={selectOfferOpen}
                    className="search-modal-wrapper"
                    >
                        <Box className="search-modal types">
                            {showLogin || 
                            <div className="type-cards">
                                <div className={(selectedType ? (selectedType == CarType.Luxury ? "selected" : "unselected") : "") + " type-card"}>
                                <img src={require("../images/cars/luxury_saloon.png")} />
                                <div className="type-card-content">
                                    <span className="type-card-header">Luxury Saloon</span>
                                    <span className="type-card-name">Mercedes-Benz S Class or similar</span>
                                    <span>Passengers: 3</span>
                                    <span>Checked luggage: 2</span>
                                    <span>Hand luggage: 1</span>
                                    <span>Price: {luxPrice} GBP</span>
                                </div>
                                <button onClick={() => onSelectClick(CarType.Luxury)} className="primary-button">{selectedType == CarType.Luxury ? "Selected" : "Select"}</button>
                                {selectedType == CarType.Luxury &&
                                    <a onClick={() => onContinueToSummary()} className="continue-button">Continue to summary <img src={require("../images/pictograms/arrow.png")}/></a>
                                }
                                </div>
                                
                                <div className={(selectedType ? (selectedType == CarType.SevenSeater ? "selected" : "unselected") : "") + " type-card"}>
                                <img src={require("../images/cars/mercedes_v_class.png")} />
                                <div className="type-card-content">
                                    <span className="type-card-header">Premium MPV</span>
                                    <span className="type-card-name">Mercedes V Class</span>
                                    <span>Passengers: 7</span>
                                    <span>Checked luggage: 5</span>
                                    <span>Hand luggage: 4</span>
                                    <span>Price: {mpvPrice} GBP</span>
                                </div>
                                <button onClick={() => onSelectClick(CarType.SevenSeater)} className="primary-button">{selectedType == CarType.SevenSeater ? "Selected" : "Select"}</button>
                                {selectedType == CarType.SevenSeater &&
                                    <a onClick={() => onContinueToSummary()} className="continue-button">Continue to summary <img src={require("../images/pictograms/arrow.png")}/></a>
                                }
                                </div>
                                {showMoreVehicles || <div className="more-vehicles-button" onClick={() => setShowMoreVehicles(true)}><button className="secondary-button"><span>More vehicles</span><img src={require("../images/pictograms/arrow_brown.png")} /></button></div>}
                                {showMoreVehicles &&
                                <React.Fragment>
                                    <div className={(selectedType ? "unselected" : "") + " type-card"}>
                                    <img src={require("../images/cars/mercedes_jet_class.png")} />
                                    <div className="type-card-content">
                                        <span className="type-card-header">Senzati Jet Class</span>
                                        <span>Passengers: 4</span>
                                        <span>Checked luggage: 2</span>
                                        <span>Hand luggage: 2</span>
                                    </div>
                                    <button className="primary-button"><a href="https://www.simber.co.uk/contact">Get a Quote</a></button>
                                    </div>
                                    <div className={(selectedType ? "unselected" : "") + " type-card"}>
                                    <img src={require("../images/cars/rolls_royce.png")} />
                                    <div className="type-card-content">
                                        <span className="type-card-header">Rolls Royce Ghost</span>
                                        <span>Passengers: 3</span>
                                        <span>Checked luggage: 1</span>
                                        <span>Hand luggage: 2</span>
                                    </div>
                                    <button className="primary-button"><a href="https://www.simber.co.uk/contact">Get a Quote</a></button>
                                    </div>
                                    <div className={(selectedType ? "unselected" : "") + " type-card"}>
                                    <img src={require("../images/cars/minibus.png")} />
                                    <div className="type-card-content">
                                        <span className="type-card-header">Executive Minibus</span>
                                        <span>Passengers: 10 to 16</span>
                                        <span>Checked luggage: Varied</span>
                                        <span>Hand luggage: Varied</span>
                                    </div>
                                    <button className="primary-button"><a href="https://www.simber.co.uk/contact">Get a Quote</a></button>
                                    </div>
                                </React.Fragment>
                                }
                            </div>
                            }
                            {showLogin &&
                            <div className="login-wrapper">
                                {(!register && !guest && !forgotPass && ! forgotPassSuccess) &&
                                <div className="home-login-dialog">
                                    <img className="login-dialog-close-button" onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                                    <span className="home-login-header">Before we continue</span>
                                    <span className="home-login-sub-header">Either you can login</span>
                                    <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Email" className="home-login-text-input" type="text"/>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} placeholder="Password" className="home-login-text-input"/>
                                    <input className="accent-button home-login-button" type="button" value="Login" onClick={() => doLogin()}/>
                                    <span onClick={() => setForgotPass(true)} className="forgot-password-link">Forgot password?</span>
                                    <span className="home-login-create-account"><span className="home-login-create-account-text">Create an account</span></span>
                                    <input onClick={() => setRegister(true)} className="primary-button home-login-signup-button" type="button" value="Sign Up"/>
                                    <div className="login-guest-button"><span onClick={() => setGuest(true)}>or continue as a Guest</span><img onClick={() => setGuest(true)} src={require("../images/pictograms/arrow_brown.png")} /></div>
                                </div>
                                }
                                {forgotPass &&
                                <div className="home-login-dialog">
                                <img className="login-dialog-close-button" onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                                <span className="home-login-header">
                                    Forgot Password?
                                </span>
                                <span className="home-login-sub-header">Check your email and click on the link we've sent you.</span>
                                    <input
                                    placeholder="Email"
                                    className="home-login-text-input"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.currentTarget.value) }
                                    />
                                    <input
                                    type="button"
                                    className="accent-button home-login-signup-button"
                                    value="Send"
                                    onClick={() => sendResetEmail()}
                                    />
                                    <div className="login-guest-button"><span onClick={() => setForgotPass(false)}>or Login</span><img onClick={() => setForgotPass(false)} src={require("../images/pictograms/arrow_brown.png")} /></div>
                                </div>
                            }
                            {forgotPassSuccess &&
                                <div className="home-login-dialog">
                                <img className="login-dialog-close-button" onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                                <img className="forgot-pass-success-tick" src={require("../images/pictograms/tick_circle_green.png")} />
                                <span className="home-login-header email-header">Email sent!</span>
                                <span className="forgot-password-login-link">I didn't get any email? <a onClick={() => { setForgotPass(true);setForgotPassSuccess(false);}}>Send again</a></span>
                                <input type="button" className="primary-button home-login-signup-button" onClick={() => setForgotPassSuccess(false)} value="Log in" />
                                </div>
                            }
                                {register &&
                                <div className="home-login-dialog">
                                    <img className="login-dialog-close-button" onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                                    <span className="home-login-header">Register</span>
                                    <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Email" className="home-login-text-input" type="text"/>
                                    <input value={emailRe} onChange={(e) => setEmailRe(e.currentTarget.value)} placeholder="Confirm email" className="home-login-text-input" type="text"/>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} placeholder="Password" className="home-login-text-input"/>
                                    <input type="password" value={passwordRe} onChange={(e) => setPasswordRe(e.currentTarget.value)} placeholder="Confirm password" className="home-login-text-input"/>
                                    <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Full Name" className="home-login-text-input"/>
                                    <PhoneInput
                                        regions={'europe'}
                                        country={'gb'}
                                        enableSearch
                                        onChange={(val) => setPhone(val)}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox className="checkbox" checked={spam} onChange={() => setSpam(!spam)}/>
                                        }
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                    <FormControlLabel
                                        className="eighteen"
                                        control={
                                            <Checkbox className="checkbox" checked={eighteen} onChange={() => setEighteen(!eighteen)}/>
                                        }
                                        label="I am 18 years or older."
                                    />
                                    <input className="primary-button home-login-button" type="button" value="Sign Up" onClick={() => doRegister()}/>
                                    <div className="login-guest-button"><span onClick={() => setRegister(false)}>or Login</span><img onClick={() => setRegister(false)} src={require("../images/pictograms/arrow_brown.png")} /></div>
                                </div>
                                }
                                {guest && 
                                <div className="home-login-dialog">
                                    
                                    <React.Fragment>
                                        <img className="login-dialog-close-button" onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                                        <span className="home-login-header">Guest</span>
                                        <span className="home-login-sub-header">Either you can login</span>
                                        <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Full Name" className="home-login-text-input"/>
                                        <PhoneInput
                                        regions={'europe'}
                                        country={'gb'}
                                        enableSearch
                                        onChange={(val) => setPhone(val)}
                                        />
                                        <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Email" className="home-login-text-input" type="text"/>
                                        <input value={emailRe} onChange={(e) => setEmailRe(e.currentTarget.value)} placeholder="Confirm email" className="home-login-text-input" type="text"/>
                                        <input className="primary-button home-login-button" type="button" value="To Summary" onClick={() => toSummary()}/>
                                        <div className="login-guest-button"><span onClick={() => setGuest(false)}>or Login</span><img onClick={() => setGuest(false)} src={require("../images/pictograms/arrow_brown.png")} /></div>
                                    </React.Fragment>
                                </div>
                                }
                            </div>
                            }
                        <img className={showLogin ? "login-close-button-hide" : ""} onClick={() => {closeOfferDialog()}} src={require("../images/pictograms/close_pictogram_brown.png")} />
                        </Box>
                    </Modal>
                </Box>
                </Modal>
                <Portal>
                  <Snackbar
                  open={errorMsg ? true : false}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="error">
                    {parseError(errorMsg)}
                  </Alert>
                </Snackbar>
              </Portal>
              </React.Fragment>
            )
}

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes>) =>
    bindActionCreators(
      {
        saveToken,
        saveState
      },
      dispatch
    );
  
  const mapStateToProps = (state: RootState): IMappedProps => {
    return {
      token: state.user.token
    }
  }
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(SearchComponent);

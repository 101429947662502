import React from "react";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import MetaTags from 'react-meta-tags';
import "./PaymentCanceledPage";

export default function NotFound() {
    return (
        <div className="wrapper">
          <MetaTags>
              <meta name="description" content="Simber Chauffeur Services Page not found" />
              <title>Page Not Found | Simber</title>
          </MetaTags>
          <HeaderComponent />
          <main className="payment-cancelled-wrapper">
            <Container style={{alignItems: "center"}} className="payment-cancelled-card" maxWidth="sm">
              <img style={{height: "42px", width: "42px", marginBottom: "10px"}} src={require("../images/pictograms/alert_pictogram.png")} />
              <h2>Page not found!</h2>
              <p>
                The page you tried to reach does not exist.
              </p>
              <NavLink to="/" className="payment-cancelled-link">
                  Return to Homepage
              </NavLink>
            </Container>
          </main>
          <FooterComponent />
        </div>
    );
}
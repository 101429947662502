import React from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import store from './redux/store'
import { Button } from '@material-ui/core';
import { acceptCookie } from './redux/actions';
import RouterComponent from './RouterComponent';
import history from './history';
import {Router} from 'react-router-dom'
import "@babel/polyfill";

require('dotenv').config()
require("@babel/polyfill");

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router history={history}>
        <RouterComponent />
      </Router>
      { store.getState().user.cookie &&
        <div className="cookie">
          <span>By using our website you accept that the third party applications such as google used by our website may use cookies to track information.</span>
          <button className="primary-button" onClick={() => {store.dispatch(acceptCookie()); window.location.reload();}}>Confirm</button>
        </div>
      }
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);


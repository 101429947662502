import React from "react";
import './BreadCrumbs.scss';

export interface IBreadcrumb {
    description: string;
    url: string;
    dontNavigate?: boolean;
  }
  
  const StructuredBreadcrumb = ({
    breadcrumbList,
    className
  }: {
    breadcrumbList: IBreadcrumb[];
    className?: string;
  }) => (
    <ol
      itemScope={true}
      itemType="http://schema.org/BreadcrumbList"
      className={"breadcrumbs-container hide " + className}
    >
      <li
          key={0}
          itemProp="itemListElement"
          itemScope={true}
          itemType="http://schema.org/ListItem"
        >
          <a
            href="https://www.simber.co.uk"
            itemScope={true}
            itemType="http://schema.org/Thing"
            itemProp="item"
            itemID="https://www.simber.co.uk"
          >
            <span itemProp="name">Home page</span>
          </a>
          <span className="separator">&gt;</span>
          <meta itemProp="position" content={"0"} />
      </li>
      {breadcrumbList.map((breadcrumb: IBreadcrumb, i: number) => (
        <li
          key={i+1}
          itemProp="itemListElement"
          itemScope={true}
          itemType="http://schema.org/ListItem"
        >
          <a
            className={breadcrumb.dontNavigate ? "no-navigate" : ""}
            href={breadcrumb.url}
            itemScope={true}
            itemType="http://schema.org/Thing"
            itemProp="item"
            itemID={breadcrumb.url}
          >
            <span itemProp="name">{breadcrumb.description}</span>
          </a>
          {i < breadcrumbList.length-1 &&
            <span className="separator">&gt;</span>
          }
          <meta itemProp="position" content={(i+1).toString()} />
        </li>
      ))}
    </ol>
  );
  
  export default StructuredBreadcrumb;
import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import MetaTags from "react-meta-tags";
import StructuredBreadcrumb, { IBreadcrumb } from "../BreadCrumbs";
import { AccordionComponent } from "../ServicesPages/AccordionComponent";
import "./SenzatiPage.scss";

export default function SenzatiPage() {
    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Vehicles",
          url: "https://www.simber.co.uk/vehicles",
        },
        {
          description: "Mercedes V-class",
          url: "https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel",
        },
      ];
    
      return (
        <div className="service-background-wrapper">
          <MetaTags>
            <meta
              name="description"
              content="Discover why our clients love the Jet Class Mercedes vehicle, made to meet your finest requirements and preferences. Use this vehicle for exclusive UK trips."
            />
            <title>Mercedes Senzati Jet Class London Travel</title>
            <link
              rel="canonical"
              href={"https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel"}
            />
          </MetaTags>
          <div className="service-page-wrapper">
            <div className="content-wrapper"></div>
            <HeaderComponent />
            <main>
              <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
              <div className="container">
                <div className="service-picture senzati-wrapper"></div>
                <img
                  onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                  className="top-img"
                  src={require("../../images/pictograms/top_arrow.png")}
                />
                <div className="content">
                  <h1><span>Your First-Class London Travel with Mercedes Senzati Jet Class</span></h1>
                  <p>Are you ready to take your London experience to the highest luxury level possible? Simber's fleet offers the spectacular Mercedes Senzati Jet Class - a must for your VIP travel needs.</p>
                  <p>The Jet Class is quite unique, and we are proud to offer it to our clients who prefer a bespoke cabin, advanced technology and stylish, practical design. This vehicle offers a first-class experience for VIP Travel. Every aspect of the Jet Class is designed with the passenger’s utmost comfort in mind, making every journey feel less like a commute and more like a retreat. Using the highest quality materials and properly engineered solutions, our Jet Class exudes quality.</p>
                  <p>What do you get with it?</p>
                  <img className="vehicles-img" src={require("../../images/cars/senzati2.webp")}/>
                  <ul className="dot-list">
                    <li><strong>Tranquillity</strong> - Comfortable seats including two jet seats with heating & cooling along with massage options, enough legroom so you can relax with style while rediscovering London or the UK over and over again</li>
                    <li><strong>Privacy</strong> - The whole travelling experience stays private and discreet no matter your travel needs. The vehicle includes a partition between the chauffeur & passenger cabin with an intercom connection, side curtains within the passenger cabin, tinted windows & a wonderful long sunroof with added protective heat film</li>
                    <li><strong>Convenience</strong> - The features of the Jet Class make travelling a nice way to rest and chill during the journey. Fitted with dual TV screens, a centre console with foldable tables for working on the go, a fridge & storage for your refreshments, as well as fibre optic headlining with interchangeable sky lighting</li>
                    <li><strong>Entertainment</strong> -  Connect & mirror any device, including your laptop, and enjoy your favourite show on Netflix, Amazon Prime & Apple TV while travelling. With an enhanced sound system, listening to your favourite music is a joy.</li>
                    <li><strong>Internet</strong> - Fitted with 5G internet connection on board, HDMI connection to project your presentation on the TVs fitted and 240v Mains Sockets for charging your devices</li>
                  </ul>
                  <p>Our Mercedes Senzati Jet Class is more than the usual vehicle - it's a way to express yourself and enjoy the ultimate luxury a car can offer!</p>
                  <h2>Why is Senzati Jet Class Chauffeur Hire Perfect For You?</h2>
                  <p>Senzati Jet Class is practically a customised VIP Mercedes V Class made to deliver a perfect service to any client hiring us! In the rear cabin, separated from the chauffeur, you have a seating capacity for four people; many use this vehicle for travelling for business purposes within London as well as for pleasure with family & friends to long-distance destinations.</p>
                  <p>When choosing the Mercedes Senzati Jet Class chauffeur service, you invest in:</p>
                  <ul className="dot-list">
                    <li><strong>Unmatched Comfor</strong> - Luxury experience with Jet Class's features designed to provide relaxation and productivity, making every journey feel less like a commute and more like a retreat</li>
                    <li><strong>Incomparable Interior Space</strong> - The Senzati Jet Class model features a real cinematic experience too, with amazing audio visual packages that emanate a real cinema.</li>
                    <li><strong>Expert Chauffeurs</strong> - Our trained drivers offer the highest level of professionalism to always deliver a stress-free journey for the client</li>
                    <li><strong>Impeccable Service</strong> - We add a personal touch to each journey according to what we learn for the client from the consultations</li>
                  </ul>
                  <img className="vehicles-img" src={require("../../images/cars/senzati3.webp")}/>
                  <p>Book your Mercedes Senzati Jet Class today and transform your London travel into an unforgettable experience. Contact us for a personalised quote.</p>
                  <p>Choose Mercedes Senzati Jet Class and get more than an ordinary ride around London (or UK-wide.)</p>
                  <p>
                    <button
                      className="primary-button center"
                      onClick={() =>
                        (window.location.href = "https://www.simber.co.uk/contact")
                      }
                    >
                      Contact us
                    </button>
                  </p>
                  <div className="devider"></div>
                  <h2 className="faq-section-header">FAQs</h2>
                  <div
                    className="faq-wrapper"
                    itemScope
                    itemType="https://schema.org/FAQPage"
                  >
                    <AccordionComponent
                      question="What amenities are included in the Mercedes Senzati Jet Class?"
                      answer="Mercedes-Benz"
                    />
                    <AccordionComponent
                      question="Is there a minimum booking time for the Senzati Jet Class?"
                      answer="For the best availability, we recommend booking the Jet Class in advance, especially for peak seasons or special occasions. We offer flexible booking options to suit your needs. While minimum booking times may apply, please contact us for a personalised quote."
                    />
                    <AccordionComponent
                      question="Can I use my phone or laptop to watch shows or listen to music in the Jet Class?"
                      answer="Yes, the Jet Class allows you to connect your laptop via HDMI, furthermore you can mirror your mobile devices, curate your own playlist for a personalised travel experience or use one of the apps on board to stream your favourite playlist."
                    />
                    <AccordionComponent
                      question="How many luggages can the vehicle hold?"
                      answer="The Jet Class can fit up to two medium checked luggages, however for maximum comfort in order to use the Jet Seats in the rear passenger cabin we recommend hand luggage only for all four passengers. "
                    />
                    <AccordionComponent
                      question="Is the vehicle good for long distance travel?"
                      answer="This is probably the best vehicle you can travel with long distance, the vehicle has enhanced noise insulation compared to other Mercedes V Class. You are able to close the privacy blinds, play your favourite soundtracks and help yourself to refreshments and recline the Jet Seats to a sleeping position if you wish."
                    />
                  </div>
                </div>
              </div>
            </main>
            <FooterComponent />
          </div>
        </div>
      );
}
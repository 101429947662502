import React, { useState } from 'react';
import './PasswordInput.scss';

export interface PasswordInputProps {
    placeholder: string;
    value: string;
    setValue(value: string): void;
}

export default function PasswordInput(props: PasswordInputProps) {

    const [showPass, setShowPass] = useState(false);
    
    return (
        <div className="password-input">
            <input
                type={showPass ? "text" : "password"}
                className="text-input"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => {props.setValue(e.currentTarget.value)}}
            />
            <img alt="Pictgoram showing an eye." onClick={() => setShowPass(!showPass)} src={require("../images/pictograms/show-pass-pictogram.png")} />
        </div>
    )
}
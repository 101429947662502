import React from "react";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import MetaTags from 'react-meta-tags';
import "./PaymentCanceledPage.scss"

export function PaymentCanceledPage() {
  return (
    <div  className="wrapper">
      <MetaTags>
          <meta name="description" content="Simber Chauffeur Services Payment Cancelled page" />
          <title>Payment Cancelled | Simber</title>
      </MetaTags>
      <HeaderComponent />
      <main>
        <div className="payment-cancelled-wrapper">
          <div className="payment-cancelled-card">
            <span className="payment-cancelled-header">Payment Cancelled!</span>
            <span className="payment-cancelled-content">
              You can try again or contact us if you think your payment has gone through at: bookings@simber.co.uk
            </span>
            <NavLink to="/" className="payment-cancelled-link">
                Return to Homepage
            </NavLink>
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}

import React from 'react';
import './ServiceTermsComponent.scss';

export default function ServiceTermsComponent() {
    return(
        <div className="service-terms-component">
            <h1>Service Terms and Conditions</h1>
            <ol>
                <p>Simber Chauffeur Services are responsible for both accepting the booking as well as the provision of the journey. We will use our reasonable endeavours to complete the performance of the services within the time agreed or as set out in the confirmation provided to the client upon booking.</p>
            </ol>
            <h2>Our Payment Terms & Additional Charges</h2>
            <ol>
                <li>Our payment terms for bookings unless otherwise agreed; all payments are due in full prior to any bookings. Payments for major account holders are due within 14 days of the date of invoice.</li>
                <li>For all other bookings such as major social or corporate events including weddings, we require a 25% deposit with the remaining balance due 14 days upon completion of the booking.</li>
                <li>Any refund requests once approved will be processed to the client by way of an electronic transfer or if agreed by way of a credit note added on account for future trips. Should the client opt for an electronic transfer, the refund will be processed less deductions of transfer fees that are incurred for the transfer.</li>
                <li>All prices quoted and charged are in pounds sterling (GBP) and exclusive of VAT unless otherwise stated.</li>
                <li>If you make payment by debit or credit card, the charge on your card statement will be shown as “SIMBER Chauffeur Services”.</li>
                <li>Any deviations to the original booking price, such as additional waiting time, extra hours, additional stops, additional mileage, and address changes for both pick-up and drop-off locations will be subject to additional charges. If booking an hourly service please note, all hourly service rates are subject to a 8 miles per hour allowance (example 4 hours includes an allowance of 32 miles). Additional mileages and hours will be charged as per the table below.
                    <table>
                        <tr>
                            <th></th>
                            <th>Mileage Allowance</th>
                            <th>Excess Mileage</th>
                            <th>Additional Hours</th>
                        </tr>
                        <tr>
                            <td><strong>Luxury Saloon</strong></td>
                            <td>8 miles/hour</td>
                            <td>£3.5/mile+VAT</td>
                            <td>£62.50/hour+VAT</td>
                        </tr>
                        <tr>
                            <td><strong>Premium MPV</strong></td>
                            <td>8 miles/hour</td>
                            <td>£3/mile+VAT</td>
                            <td>£52.50/hour+VAT</td>
                        </tr>
                    </table>
                </li>
                <li>Unless specified at the time of booking, SIMBER may not automatically be able to accommodate changes to bookings including additional pick-ups or drop-offs for any journey, although every effort will be made by the chauffeur(s) to meet such requirements</li>
                <li>Car parking charges that are necessarily incurred in order to fulfil a booking will be charged at cost except where this cost is incorporated into our Airport Meet & Greet service charge.</li>
                <li>If our chauffeur is asked to wait either at the beginning or during a journey, additional waiting time will be charged in 15-minute intervals and in line with the hourly rate for the vehicle type.</li>
                <h2>Waiting Times</h2>
                <li>Commercial Airport transfers include 60 minutes of complimentary wait time from the flight landing time. Additional waiting time if required will be charged in 15-minute intervals and in line with the hourly rate for the vehicle type, additional parking added at cost.</li>
                <li>Private Aviation transfers include 15 minutes of complimentary wait time from your scheduled pick up time. As the Private Jet leaves on your terms, we must ensure our chauffeur is waiting regardless of how late you land. Additional waiting time will therefore be charged in 15-minute intervals and in line with the hourly rate for the vehicle type.</li>
                <li>All inner city rides, A-B transfers include 15 minutes of complimentary wait time from the scheduled pick up time. Additional waiting time if required will be charged in 15-minute intervals and in line with the hourly rate for the vehicle type, additional parking added at cost.</li>
                <li>The cost of parking, Tolls, Congestion Charge, Eurotunnel, hotel accommodation, meals and/or event tickets which have already been purchased by SIMBER company at the request of the client, will be charged to the client, plus any administration charges incurred by the company and in advance of use.</li>
                <h2>Commercial Flight Delays</h2>
                <li>We understand the importance of having a chauffeur waiting even when commercial flights are significantly delayed. Upon acceptance of your booking, we reserve the availability of the vehicle and subsequently refuse all other bookings for that vehicle at that time & date, these delays also impact our schedules and service.</li>
                <ul>
                    <li>Clause 10 identifies the complimentary waiting times from flight landing.</li>
                    <li>In the event of any commercial flight delay of more than one (1) hour, kindly note that waiting time charges will apply from two (2) hours of the original scheduled landing time, which includes the 60 minutes complimentary waiting time explained in clause 10. Additional waiting time if required will be charged in 15-minute intervals and in line with the hourly rate for the vehicle type, additional parking added at cost.</li>
                    <li>Illustrative example: Flight Scheduled to land at 12:00, delayed to 15:00, passenger is in the vehicle at 16:30. Complimentary waiting time of 1 hour as per clause 10, complimentary delay of 1 hour. Charge applied for further 2.5 hours (14:00 to 16:30) in line with the hourly rate for the vehicle type, plus additional parking charges.</li>
                </ul>
                <h2>Public Holidays</h2>
                <li>Bookings carried out on Christmas Day, Boxing Day, New Year’s Eve from 18:00, and all-day New Year’s Day will be subject to an “unsocial hours” surcharge.</li>
                <li>Online quotations do not allow for bookings on public and bank holidays such as Christmas Day, Boxing Day, New Year’s Eve & New Years Day. We recommend our clients to Contact Us for a quotation. On Christmas Day, Boxing Day, New Year’s Eve from 18:00 and New Year’s day there will be a 100% surcharge on all journeys.</li>
                <h2>Our Cancellation Policy</h2>
                <li>Our bookings cancellation policy reflects the availability of the vehicles within the fleet. Upon acceptance of the Client booking, we reserve the availability of the vehicle and subsequently refuse all other bookings for that vehicle at that time & date.</li>
                <li>If a respective client wants to cancel or amend any details of the pre-booked services, you must tell us as soon as possible by editing the booking online or by contacting us by email or telephone. Any changes or any amendments can only be done with SIMBER and should not be discussed with the driver, they are responsible only for carrying out your journey. We will use reasonable endeavours to make any required changes, any additional costs will be included in the fees and invoiced to you. For cancellations revert to see clauses 21 & 22.</li>
                <li>In the event the Client or their respective passenger is late, the Client or their respective passenger must contact SIMBER and advise us of the same. Additional waiting time will be charged in line with the hourly rate for the vehicle type.</li>
                <li>A ride is considered a no-show if the Client, their passengers or guests, has not shown up without cancellation within 30 minutes after the agreed pickup time at the agreed pickup location. If a client, passenger, or guest does not show up, the ride must be paid for in full, whereas possible surcharges for waiting time do not apply.</li>
                <li>Car Cancellation Notices:</li>
                <ul>
                    <li>If an Airport Transfer or inner city ride A-B (within London) is cancelled or amended within 12 hours of the requested pick-up time, SIMBER reserves the right to make a full charge for the journey</li>
                    <li>If an Hourly Car Service or Long Distance booking is cancelled or amended within 24 hours of the requested pick-up time, SIMBER reserves the right to make a full charge for the journey.</li>
                    <li>If a Long Term booking (Multi Day Hourly Service) is cancelled or amended within 72 hours of the requested pick-up time, SIMBER reserves the right to make a full charge for the journey.</li>
                </ul>
                <li>Minibus Cancellation Notices:</li>
                <ul>
                    <li>
                        For all Minibus bookings: Minibus Airport Transfer, Minibus Tour, Minibus Inner City Transfer, Minibus Long Distance, Minibus Hourly or Daily Service, cancelled or amended the following scale of charges will apply in relation to the total hire charge. Illustrated in the table below.
                        <table>
                            <tr>
                                <th>Days prior to departure date</th>
                                <th>Total hire charge</th>
                            </tr>
                            <tr>
                                <td>29 days or more</td>
                                <td>25% of the hire</td>
                            </tr>
                            <tr>
                                <td>15 to 28 days</td>
                                <td>50% of the hire</td>
                            </tr>
                            <tr>
                                <td>8 to 14 days</td>
                                <td>75% of the hire</td>
                            </tr>
                            <tr>
                                <td>1 to 7 days</td>
                                <td>100% of the hire</td>
                            </tr>
                        </table>
                    </li>
                </ul>
                <li>For all Minibus bookings departing from commercial airports such as London Heathrow & Gatwick Airport:</li>
                <ul>
                    <li>Coaches must park in the designated coach park, where they are held by security until your flight has landed and you have cleared arrivals. To ensure a fast and smooth transfer to your coach we would recommend that you book a Personal Meet and Greet Assistant who will meet you in the arrivals hall and escort you to the vehicle.</li>
                    <li>A Meet and Greet assistant can be booked (subject to availability) for an additional fee of £125. If you do not book a meet and greet in advance please report to the Information Desk in the arrivals hall and ask to be directed to a Coach Marshall. Once you have made contact with the Coach Marshall, the coach will be permitted to leave the coach park and you will be directed to meet the coach at one of the designated bus stops</li>
                </ul>
                <li>Cancellation by the client due to severe weather conditions will be charged as above, please refer to clause 21 & 22 for the different vehicle types.</li>
                <li>Cancellation of an event or holiday or “reason for travel” does not affect the client's liability for the above (clause 21 & 22) cancellation fees and the monies will be due as if the vehicle was travelling. Should the client not have paid the amount set out above at the time of cancellation the balance shall become due immediately and shall be a debt owed to the Company. The Company may, at its sole discretion, engage the services of a debt collection agency to recover any unpaid amount together with interest and any debt collection charges.</li>
                <li>Any pre-booked costs such as accommodation, ferry or train crossings, parking, event tickets and meals which have already been purchased by SIMBER LTD at the request of the client, will be charged to the client, plus any administration charges incurred by SIMBER LTD and in advance of use.</li>
                <li>Cancellation policy for major corporate and social events:</li>
                <ul>
                    <li>If a Wedding Chauffeur Service is cancelled within 14 days from the start of the booking hire, the deposit taken will be non-refundable. If the Wedding Chauffeur Service is cancelled within 7 days from the start of the booking hire, the full booking hire amount will be charged.</li>
                    <li>If a major social or corporate event is cancelled within 14 days from the start of the booking hire, the deposit taken will be non-refundable. If within 7 days of a major social or corporate event the chauffeur service is cancelled by the client, the full booking amount will be charged.</li>
                </ul>
                <h2>Service Terms and Conditions Continued</h2>
                <li>SIMBER requests a minimum of 12 hours’ notice for any online bookings, if you would like to book a vehicle with less than 12 hours’ notice, please call our office on + (44) 203 983 5383. For all online bookings made between the hours of 21:00 and 06:00 that are also due to be carried out within the same time frame, we ask our clients to call our office on + (44) 203 983 5383 as we cannot confirm online bookings during unsocial hours.</li>
                <li>SIMBER will always endeavour to ensure vehicle(s) arrive at the time and place requested. We cannot however accept responsibility for delays caused by circumstances beyond our control, such as mechanical failure, traffic jams, traffic accidents, or extreme weather conditions. Nor do we accept responsibility for any consequential loss.</li>
                <li>The chauffeur(s) will travel by the most appropriate route on the day of the booking unless instructed otherwise by the Client, in which case Payment Clause 5. may apply.</li>
                <li>The chauffeur(s) will always drive at a sensible speed in accordance with the road, traffic, and legal speed limits.</li>
                <li>In the highly unlikely event during a booking our vehicle develops a mechanical failure, tyre puncture, car accident or similar which is beyond our control, we SIMBER have the right to request any vehicle type like for like or not (at our cost) to get you to your destination as soon as possible. However we do not accept responsibility for any consequential loss.</li>
                <li>SIMBER shall be under no liability whatsoever to the customer for any indirect loss (including loss of profit) suffered by the Client, passenger, or customer arising out of the chauffeur service provided.</li>
                <li>Our passengers/clients are responsible for any damage caused to the interior or exterior of a vehicle on hire and will be billed accordingly for any repairs requested in order to reinstate a vehicle to working order. If special cleaning of any vehicle is required because it has been left in an unreasonable state by a passenger/client, we reserve the right to charge the cost of cleaning to that passenger/client.</li>
                <li>We reserve the right to refuse entry to our vehicles to anyone who is thought to be under the influence of alcohol or drugs and/or whose behaviour poses a threat to the safety of the driver, the vehicle, or other passengers within the vehicle. We further reserve the right to terminate any journey due to unreasonable or improper behaviour by any passenger.</li>
                <li>SIMBER reserves the right to subcontract a vehicle or chauffeur/driver. SIMBER vehicle(s) and sub-contracted vehicles are fully insured for our passengers and third-party claims. However, passengers/clients’ properties are carried at their own risk and SIMBER will not be held responsible nor liable for any loss/damage to such property.</li>
                <li>Nothing contained in these terms and conditions affects the Client's statutory rights.</li>
                <h2>Complaints Procedure</h2>
                <li>Simber Chauffeur Services are very proud of our high standards of customer service. However, in the event that we fail in meeting these standards, please do not hesitate to write to us or contact us by email clientcare@simber.co.uk</li>
            </ol>
        </div>
    )
}
import { Button, Container, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../config/Axios.config';
import { ContactReasons, ContactUsDto } from '../dtos/ContactData';
import "./ContactUsPage.scss";
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from './BreadCrumbs';

type ContactReasonOption = {
    value: ContactReasons,
    label: string
}

export default function ContactUsPage() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [bookingNumber, setBookingNumber] = useState("");
    const [selectedReason, setSelectedReason] = useState<ContactReasons | null>(null)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [init, setInit] = useState(true);

    useEffect(() => {
        if(init) {
        window.scrollTo({top:0})
        setInit(false);
        }
    })

    const options: ContactReasonOption[] = [
        {
            value: ContactReasons.Airport,
            label: "Airport Transfers"
        },
        {
            value: ContactReasons.Corporate,
            label: "Corporate Chauffeurs"
        },
        {
            value: ContactReasons.Event,
            label: "Event Chauffeuring"
        },
        {
            value: ContactReasons.Wedding,
            label: "Wedding"
        },
        {
            value: ContactReasons.Private,
            label: "Private tours"
        },
        {
            value: ContactReasons.CruisePortTransfer,
            label: "Cruise port transfer"
        },
        {
            value: ContactReasons.LongDistance,
            label: "Long distance transfers"
        },
        {
            value: ContactReasons.LongTerm,
            label: "Long term transfers"
        },
        // {
        //     value: ContactReasons.LostAndFound,
        //     label: "Lost & Found"
        // },
        {
            value: ContactReasons.Other,
            label: "Other"
        },
    ]

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedReason(event.target.value as ContactReasons);
    }

    const handleMessageChange = (val: string) => {
        if(val.length < 500) {
            setMessage(val);
        }
    }

    const trySendLostFound = () => {
        if(selectedReason != ContactReasons.LostAndFound)
            setSelectedReason(ContactReasons.LostAndFound);
        else
            trySendContact();
    }

    useEffect(() => {
        if (selectedReason == ContactReasons.LostAndFound) {
            trySendContact();
        }
    }, [selectedReason]);

    const trySendContact = () => {
        var errors = "";
        if(name.length === 0) {
            errors += "Name cannot be empty.\n";
        }
        if(email.length === 0) {
            errors += "Email cannot be empty.\n";
        }
        if(selectedReason !== ContactReasons.LostAndFound) {
            if(number.length === 0) {
                errors += "Number cannot be empty.\n";
            }
        }
        if(message.length <= 10) {
            errors += "Message too short.\n";
        }
        if(selectedReason === undefined) {
            errors += "Please serect the reason your're contacting us.\n";
        }
        if(selectedReason === ContactReasons.LostAndFound) {
            if(bookingNumber.length === 0) {
                errors += "Booking number cannot be empty\n";
            }
        }

        if(errors !== ""){
            setError(errors);
            return;
        }
        else {
            var data: ContactUsDto = {
                contactNumber: number,
                contactReason: selectedReason as ContactReasons,
                email: email,
                message: message,
                name: name,
                bookingNumber: selectedReason === ContactReasons.LostAndFound ? bookingNumber : undefined
            }
            axiosInstance.post("user/contact", data)
                .then(response => {
                    setSuccess("You have successfully contacted us! An email confirmation should arrive shortly.")
                    setBookingNumber("")
                    setName("");
                    setEmail("");
                    setNumber("");
                    setMessage("");
                    setSelectedReason(null);
                    setError("");
                })
                .catch(error => {
                    if(error.status === 419) {
                        localStorage.clear();
                        window.location.href = "/";
                        return;
                    }
                    if (error.response?.status === 404) {
                        setError("Unknown error occured");
                    }
                    else {
                        if (error.response?.data !== undefined) {
                            setError(error.response.data.message);
                        }
                        else {
                            setError("Cannot reach server");
                        }
                    }
                })
        }
        
    }

    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Contact Us",
          url: "https://www.simber.co.uk/contact"
        },
    ]

    return(
        <div className="wrapper">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services Contact Page" />
                <title>Contact Us | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/contact" />
            </MetaTags>
            <HeaderComponent />
            <main>
                <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                <Container maxWidth="md" className="contact-us-form">
                    <h1>Contact Us</h1>
                    <span className="sub-header">To discuss your requirements, share details regarding your transportation needs, or ask for additional information about our services, please fill the contact form below and our team will get in touch with you promptly.  </span>
                    <span className="form-header">Please complete the form below if you have any questions regarding our services. We will do our best to respond as quickly as possible.</span>
                    <input value={name} onChange={(v) => setName(v.currentTarget.value)} type="text" placeholder="Name:" />
                    <input value={number} onChange={(v) => setNumber(v.currentTarget.value)} type="text" placeholder="Contact Number:" />
                    <input value={email} onChange={(v) => setEmail(v.currentTarget.value)} type="text" placeholder="Email:" />
                    <FormControl variant="filled" className="select-control">
                        <InputLabel>Choose Your Subject</InputLabel>
                        <Select
                            variant="outlined"
                            value={selectedReason}
                            onChange={(v) => handleSelectChange(v)}
                            IconComponent={(props) => {return (<img className="select-icon-image" src={require("../images/pictograms/arrow_brown.png")}></img>)}}
                            >
                            {options.map(o => {
                                return (
                                    <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {/* {selectedReason === ContactReasons.LostAndFound && 
                        <input value={bookingNumber} onChange={(v) => setBookingNumber(v.currentTarget.value)} className="booking-number-input" type="text" placeholder="Booking Number:" />
                    } */}
                    <div className="message-wrapper">
                    <TextField
                            value={message}
                            onChange={v => handleMessageChange(v.currentTarget.value)}
                            multiline={true}
                            variant="filled"
                            maxRows={10}
                            rows={6}
                            placeholder="Message:"
                        />
                        <span className="counter">{message.length}/500</span>
                    </div>
                    <div className="button-wrapper">
                        <button className="contact-button primary-button" onClick={() => trySendContact()}>Contact Us</button>
                    </div>
                    <h2>Lost & Found</h2>
                    <span className="form-header">If you’ve left something in one of our vehicles, please don’t worry, we keep all items we find in our London office.</span>
                    <input value={name} onChange={(v) => setName(v.currentTarget.value)} type="text" placeholder="Name:" />
                    <input value={email} onChange={(v) => setEmail(v.currentTarget.value)} type="text" placeholder="Email:" />
                    <input value={bookingNumber} onChange={(v) => setBookingNumber(v.currentTarget.value)} className="booking-number-input" type="text" placeholder="Booking Number:" />
                    <div className="message-wrapper message-lf">
                    <TextField
                            value={message}
                            onChange={v => handleMessageChange(v.currentTarget.value)}
                            multiline={true}
                            variant="filled"
                            maxRows={10}
                            rows={6}
                            placeholder="Message:"
                        />
                        <span className="counter">{message.length}/500</span>
                    </div>
                    <div className="button-wrapper">
                        <button className="contact-button primary-button" onClick={() => trySendLostFound()}>Send</button>
                    </div>
                </Container>
            </main>
            <Snackbar
                open={error !== ""}
                onClose={() => setError("")}
                >
                <Alert onClose={() => setError("")} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={success !== ""}
                onClose={() => setSuccess("")}
                >
                <Alert onClose={() => setSuccess("")} severity="success">
                    {success}
                </Alert>
            </Snackbar>
            <FooterComponent />
        </div>
    )
}
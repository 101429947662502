import {
  Container,
  Dialog,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { axiosInstance } from "../../config/Axios.config";
import { CarType, RentStatus, RentType } from "../../dtos/PriceData";
import RentDetailDto from "../../dtos/RentDetailDto";
import "./BookingDetails.scss";
import ChangeBookingDataDto from "../../dtos/ChangeBookingDataDto";
import { getMinDate } from "../../redux/reducers/summaryReducer";
import { Redirect } from "react-router";
import FooterComponent from "../FooterComponent";
import HeaderComponent from "../HeaderComponent";
import MetaTags from "react-meta-tags";
import DatePickerComponent from "../DatePicker/DatePickerComponent";

export default function BookingDetails() {
  const [init, setInit] = useState(true);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [error, setError] = useState("");
  const id = document.URL.split("/").pop() ?? "";
  const [data, setData] = useState<RentDetailDto>();
  const [editing, setEditing] = useState(false);
  const [passengerPhone, setPassengerPhone] = useState("");
  const [passengerName, setPassengerName] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [date, setDate] = useState<Date>();
  const [comment, setComment] = useState("");
  const [cancelOpen, setCancelOpen] = useState(false);
  const [editable, setEditable] = useState(true);
  const [cancelledRedirect, setCancelledRedirect] = useState(false);
  const [cancelApproovedOpen, setCancelApproovedOpen] = useState(false);

  const updateBookingData = () => {
    var minDate = getMinDate();
    if(data?.rentType == RentType.ByTheHour) {
      minDate = new Date(minDate.setHours((minDate.getHours() + 12)));
    }
    if (date! < minDate) {
      setError("Online reservations cannot be made within " + (data?.rentType == RentType.ByTheHour ? "24" : "12") + " hours of the requested booking time. Please contact us on +44 203 983 5383 so we can acconodate your request");
      return;
    }
    var temp = data as RentDetailDto;
    temp.passengerName = passengerName;
    temp.passengerPhone = passengerPhone;
    temp.flightTrainNumber = flightNumber;
    temp.date = date!;
    temp.comment = comment;
    var editData: ChangeBookingDataDto = {
      comment: temp.comment,
      date: temp.date,
      flightTrainNumber: temp.flightTrainNumber,
      id: id,
      passengerName: temp.passengerName,
      passengerPhone: temp.passengerPhone,
    };
    axiosInstance
      .post("profile/bookings/" + id + "/edit", editData)
      .then((result) => {
        setFeedbackOpen(true);
        setData(temp);
        setEditing(false);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setError("Unknown error occured");
        } else {
          if (err.response?.status === 419) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }
          if (err.response?.data !== undefined) {
            setError(err.response.data.message);
          } else {
            setError("Cannot reach server");
          }
        }
      });
  };

  const cancelBooking = () => {
    axiosInstance
      .delete("profile/bookings/" + id + "/cancel")
      .then((result) => {
        setCancelOpen(false);
        setCancelApproovedOpen(true);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setError("Unknown error occured");
        } else {
          if (err.response?.status === 419) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }
          if (err.response?.data !== undefined) {
            setError(err.response.data.message);
          } else {
            setError("Cannot reach server");
          }
        }
      });
  };

  useEffect(() => {
    if (init) {
      axiosInstance.defaults.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axiosInstance
        .get<RentDetailDto>("profile/bookings/" + id)
        .then((result) => {
          var date = new Date(result.data.date);
          setData({ ...result.data, date: date });
          setPassengerName(result.data.passengerName);
          setPassengerPhone(result.data.passengerPhone);
          setFlightNumber(result.data.flightTrainNumber);
          setDate(result.data.date);
          setComment(result.data.comment);
          var minDate = getMinDate();
          if(data?.rentType == RentType.ByTheHour) {
            minDate = new Date(minDate.setHours((minDate.getHours() + 12)));
          }
          if (date < minDate) {
            setEditable(false);
          }
          if (result.data.status !== RentStatus.Payed) {
            setEditable(false);
          }
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            setError("Unknown error occured");
          } else {
            if (err.response?.status === 419) {
              localStorage.clear();
              window.location.href = "/";
              return;
            }
            if (err.response?.data !== undefined) {
              setError(err.response.data.message);
            } else {
              setError("Cannot reach server");
            }
          }
        });
      setInit(false);
    }
  });
  if (cancelledRedirect) {
    return <Redirect push to="/profile/bookings" />;
  } else {
    return (
      <div className="wrapper">
        <MetaTags>
          <meta
            name="description"
            content="Simber Chauffeur Services Profile"
          />
          <title>Profile | Simber</title>
        </MetaTags>
        <HeaderComponent />
        <main className="booking-detail-wrapper">
          <div className="back-link" onClick={() => setCancelledRedirect(true)}><img src={require("../../images/pictograms/arrow_orange.png")}/><span>My Bookings</span></div>
          <div id="booking-details" className="booking-details">
            <Container maxWidth="md" className="container-card">
              <div className="booking-detail-header"><span>{data?.origin}</span><img src={require("../../images/pictograms/half_arrow.png")}/><span>{data?.destination ?? "-"}</span></div>
              <div className="summary-user-data">
                <Grid container spacing={0}>
                  <Grid className="header" xs={2} item>
                    <span>Name:</span>
                  </Grid>
                  <Grid className="data" xs={10} item>
                    {data?.name !== "" ? (
                      <span className="name">{data?.name}</span>
                    ) : data?.name === "" ? (
                      <span className="name">&#8212;</span>
                    ) : (
                      <span className="name">{data?.name}</span>
                    )}
                  </Grid>
                  <Grid className="header" xs={2} item>
                    <span>Booking number:</span>
                  </Grid>
                  <Grid className="data" xs={10} item>
                    {data?.identifier}
                  </Grid>
                  <Grid className="header" xs={2} item>
                    <span>Contact:</span>
                  </Grid>
                  <Grid className="data contacts-row" xs={10} item>
                    <span className="phone">{data?.phone}</span>
                    <span className="email">Email: {data?.email}</span>
                  </Grid>
                  <Grid className="header" xs={2} item>
                    <span>Passenger Name & Contact:</span>
                  </Grid>
                  <Grid className="data passenger-contacts-row" xs={10} item>
                    {editing && (
                      <React.Fragment>
                        <input
                          className="name-input"
                          placeholder="Passenger Name"
                          value={passengerName}
                          onChange={(val) =>
                            setPassengerName(val.currentTarget.value)
                          }
                        />
                        <PhoneInput
                          enableSearch
                          regions={"europe"}
                          country={"gb"}
                          value={passengerPhone}
                          onChange={(val) => setPassengerPhone(val)}
                        />
                      </React.Fragment>
                    )}
                    {editing || (
                      <React.Fragment>
                        <span className="passenger-name">
                          {data?.passengerName}
                        </span>
                        <span>{data?.passengerPhone}</span>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid className="header" xs={2} item>
                    <span className="flight-header">
                      Flight, Train, or Cruise No.
                    </span>
                  </Grid>
                  <Grid className="data" xs={10} item>
                    {editing && (
                      <input
                        className="flight-number"
                        placeholder="Flight Number..."
                        value={flightNumber}
                        onChange={(e) => setFlightNumber(e.currentTarget.value)}
                      />
                    )}
                    {editing || <span>{!!data?.flightTrainNumber ? data?.flightTrainNumber : "-" }</span>}
                  </Grid>
                </Grid>
              </div>
              <div className="sumamry-booking-data">
                <Grid container spacing={0}>
                  <Grid xs={2} className="header" item>
                    <span>Time & Date:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    {!editing && data !== undefined && (
                      <span>
                        {data?.date.getFullYear() +
                          "/" +
                          (data?.date.getMonth() + 1) +
                          "/" +
                          data?.date.getDate() +
                          " " +
                          (data?.date.getHours() % 12) +
                          ":" +
                          (data?.date.getMinutes() < 10
                            ? "0" + data?.date.getMinutes()
                            : data?.date.getMinutes()) +
                          (data?.date.getHours() >= 12 ? " pm" : " am")}
                      </span>
                    )}
                    {editing && (
                      // <input
                      //   id="date-picker"
                      //   value={date}
                      //   min={getMinDate()
                      //     .toISOString()
                      //     .substring(0, getMinDate().toISOString().length - 5)}
                      //   onChange={(v) => setDate(v.currentTarget.value)}
                      //   className="datepicker"
                      //   type="datetime-local"
                      // />
                      <DatePickerComponent dateSet={true} valueChanged={(val) => setDate(val)} initValue={data?.date}/>
                    )}
                  </Grid>
                  <Grid xs={2} className="header" item>
                    <span>Vehicle Type:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    <span>
                      {data?.carType === 1 ? "Luxury" : "Seven seater"}
                    </span>
                  </Grid>
                  <Grid xs={2} className="header" item>
                    <span>From:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    <span>{data?.origin}</span>
                  </Grid>
                  <Grid xs={2} className="header" item>
                    {data?.rentType === RentType.Oneway && (
                      <span>Destination:</span>
                    )}
                    {data?.rentType === RentType.ByTheHour && (
                      <span>Rent duration:</span>
                    )}
                  </Grid>
                  <Grid xs={10} className="data" item>
                    {data?.rentType === RentType.Oneway && (
                      <span>{data?.destination}</span>
                    )}
                    {data?.rentType === RentType.ByTheHour && (
                      <span>{data?.hours} hours</span>
                    )}
                  </Grid>
                  {data?.preferences.some((p) => p.Value) && (
                    <Grid xs={2} className="header" item>
                      <span>Selected Preferences:</span>
                    </Grid>
                  )}
                  {data?.preferences.some((p) => p.Value) && (
                    <Grid xs={10} className="data" item>
                      {data?.preferences.some((p) => p.Value) && (
                        <div className="preferences">
                          {data?.preferences.map(
                            (pref) =>
                              pref.Value && (
                                <span id={"preference" + pref.Id}>
                                  {pref.Text +
                                    (data?.preferences
                                      .filter((x) => x.Value === true)
                                      .indexOf(pref) !==
                                    data?.preferences.filter(
                                      (x) => x.Value === true
                                    ).length -
                                      1
                                      ? ", "
                                      : "")}
                                </span>
                              )
                          )}
                        </div>
                      )}
                    </Grid>
                  )}
                  <Grid xs={2} className="header" item>
                    <span>Additional Travel Info:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    {editing && (
                      <div className="comment-row">
                        <TextField
                          value={comment}
                          onChange={(v) => {
                            if (comment.length < 100)
                              setComment(v.currentTarget.value);
                          }}
                          className="comment"
                          label="Comment"
                          multiline
                          rows={4}
                          variant="outlined"
                        />
                        <span className="comment-length">
                          {comment.length + "/100"}
                        </span>
                      </div>
                    )}
                    {editing || (
                      <span className="comment">{data?.comment}</span>
                    )}
                  </Grid>
                  <Grid xs={2} className="header" item>
                    <span>Price:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    <span>{data?.price?.toString() + " " + "GBP"}</span>
                  </Grid>
                  <Grid xs={2} className="header" item>
                    <span>Reservation Status:</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    {data?.status && RentStatus[data?.status]}
                  </Grid>
                  <Grid xs={2} className="header" item>
                    <span>Discount Code</span>
                  </Grid>
                  <Grid xs={10} className="data" item>
                    <div className="discount-wrapper">
                      <span className="discount">
                        {data?.discount !== null
                          ? data?.discount
                          : "No discount calculated."}
                      </span>
                      <div className="buttons">
                        {!editable || editing || (
                          <button
                            className="accent-button cancel-button"
                            onClick={() => setCancelOpen(true)}
                          >Cancel Booking</button>
                        )}
                        {!editing && editable && (
                          <button
                            className="primary-button edit-booking-button"
                            onClick={() => setEditing(true)}
                          >Edit Booking</button>
                        )}
                      </div>
                      {editing && (
                        <button
                          className="primary-button"
                          onClick={() => updateBookingData()}
                        >Apply Changes</button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
            <Snackbar open={error !== ""}>
              <Alert onClose={() => setError("")} severity="error">
                {error}
              </Alert>
            </Snackbar>
            <Dialog
              className="dialog"
              open={feedbackOpen}
              maxWidth="md"
              onClose={() => setFeedbackOpen(false)}
            >
              <img src={require("../../images/pictograms/tick_circle_green.png")} />
              <span>
                Your changes have been confirmed<br /> and will reflect in your bookings.
              </span>
              <span onClick={() => setFeedbackOpen(false)} className="close">
                X
              </span>
            </Dialog>
            <Dialog
              className="cancel-dialog"
              open={cancelOpen}
              maxWidth="lg"
              onClose={() => setCancelOpen(false)}
            >
              <div className="cancel-warning">
                {
                    editable ||
                    <img
                      alt="Pictogram of an exclamation mark inside a triange, representing a warning."
                      src={require("../../images/pictograms/alert_pictogram.png")}
                    />
                }
                {
                    editable &&
                    <img
                      src={require("../../images/pictograms/tick_circle_green.png")}
                    />
                }
                <span>
                  {
                    editable || ("Bookings cancelled " + (data?.rentType == RentType.ByTheHour ? "24" : "12") + " hours prior to their scheduled time are non refundable.")
                  }
                  {
                    editable && ("Your booking cancellation is eligable for refund.")
                  }
                </span>
              </div>
              <div className="dialog-part">
                <span className="cancel-booking-text">Cancel booking from</span>
                <span className="cancel-secondary">{data?.origin}</span>
                {data?.rentType === RentType.Oneway ? (
                  <React.Fragment>
                    <span className="cancel-secondary">to</span>
                    <span className="cancel-secondary">{data?.destination}?</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="cancel-secondary">{data?.hours} hours?</span>
                  </React.Fragment>
                )}
                <button
                  className="primary-button confirm-button"
                  onClick={() => {
                    cancelBooking();
                  }}
                >Confirm</button>
                <span onClick={() => setCancelOpen(false)} className="close">
                  X
                </span>
              </div>
            </Dialog>
            <Dialog className="dialog" open={cancelApproovedOpen} maxWidth="lg">
              <img src={require("../../images/pictograms/tick_circle_green.png")} />
              <span className="large-secondary-text">
                Your booking has been cancelled.
              </span>
              <span className="small-text">
                Your payment, if refundable will be processed in 72 hours.
              </span>
              <button
                className="primary-button confirm-button"
                onClick={() => {
                  setCancelledRedirect(true);
                }}
              >Confirm</button>
            </Dialog>
          </div>
        </main>
        <FooterComponent />
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import MetaTags from "react-meta-tags";
import StructuredBreadcrumb, { IBreadcrumb } from "../BreadCrumbs";
import "../BlogDetailPage.scss";
import { AccordionComponent } from "../ServicesPages/AccordionComponent";
import SearchComponent from "../SearchComponent";
import "./MercedesSClassPage.scss";

export default function MercedesSClassPage() {

  const [bookingOpen, setBookingOpen] = useState(false);
  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Vehicles",
      url: "https://www.simber.co.uk/vehicles",
    },
    {
      description: "Mercedes S-class",
      url: "https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire",
    },
  ];

  return (
    <div className="service-background-wrapper">
      <MetaTags>
        <meta
          name="description"
          content="Discover the beautiful Mercedes S Class and hire this car for the best chauffeur service in London or around the UK - the real epitome of luxury travelling."
        />
        <title> Mercedes S Class Chauffeur Hire | London & UK</title>
        <link
          rel="canonical"
          href={"https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire"}
        />
      </MetaTags>
      <div className="service-page-wrapper">
        <div className="content-wrapper"></div>
        <HeaderComponent />
        <main>
          <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
          <div className="container">
            <img
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="top-img"
              src={require("../../images/pictograms/top_arrow.png")}
            />
            <div className="service-picture  s-class-wrapper"></div>
            <div className="content">
              <h1><span>Hire a Mercedes S Class Chauffeur in London and the UK</span></h1>
              <p>Need a Mercedes S Class chauffeur in London or the UK? Our professional drivers provide exceptionally seamless and stylish transportation in the pinnacle of luxury cars. We welcome clients who need transportation for business trips, sightseeing tours, and special occasions.</p>
              <h2>Experience Luxury Service with Our<br/>Mercedes S Class Chauffeur Package</h2>
              <p>Mercedes S Class redefines everything we know about chauffeur services and London travel. By offering nothing less than perfect service, our clients get:</p>
              <ul className="dot-list">
                <li>Spacious, impeccably crafted interior with plush leather seating and temperature control</li>
                <li>Sleek, sophisticated S-Class design for effortless style on arrival</li>
                <li>Complimentary Wi-Fi, in-cabin features, and bottled water</li>
                <li>Dedicated chauffeur for seamless travel and personalized service</li>
                <li>Advanced noise-cancellation technology for a smooth, quiet ride</li>
              </ul>
              <p>Experience the luxury of travelling in London and the UK with our Mercedes S Class chauffeur hire.</p>
              <h2>Why Choose a Mercedes S Class Chauffeur Service?</h2>
              <p>By choosing our Mercedes S Class Chauffeur in London, you're focusing on what matters most - you.</p>
              <p>Embrace the luxury and hassle-free experience around the city, and always have:</p>
              <ul className="dot-list">
                <li>
                  <strong>Effortless Travel</strong> - Arrive on time and relaxed, letting your chauffeur navigate London's traffic.</li>
                <li>
                  <strong>Unparalleled Comfort</strong> - Work or unwind in spacious luxury with plush seating and climate control.</li>
                <li>
                  <strong>Turn Heads</strong> - Make a powerful first impression with the iconic S-Class design.</li>
                <li>
                  <strong>Seamless Productivity</strong> -  Stay connected with Wi-Fi and work efficiently using in-cabin features.</li>
                <li>
                  <strong>Discretion and Expertise</strong> - Enjoy complete privacy and benefit from your chauffeur's local knowledge.</li>
              </ul>
              <p>The choice is simple and obvious - hire a Mercedes S Class chauffeur for your London adventure. But don't limit yourself to it, because you can <a href="https://www.simber.co.uk/services/long-distance-chauffeur">travel anywhere around the UK</a> or in some European metropoles.</p>
              <img className="vehicles-img" src={require("../../images/cars/mercedes-s2.webp")}/>
              <h3>The Best of Mercedes First-Class Comfort</h3>
              <p>The days of uncomfortable taxi backseats are in the past. A Mercedes S Class chauffeur service always puts the client on the backseat throne they deserve. Here's what you get if you hire a Mercedes S-Class chauffeur for your London trip:</p>
              <ul className="dot-list">
                <li>Spacious interior to stretch out and relax</li>
                <li>Plush seating to melt the stress away</li>
                <li>Climate control for a perfect ride</li>
              </ul>
              <p>Arrive relaxed and refreshed, not like you just wrestled a subway crowd. The S-Class chauffeur hire will surely help with it.</p>
              <h3>Productivity and Entertainment</h3>
              <p>With our S-Class, you'll surely see how it feels to be pampered but also to have a mobile office or a riding sanctuary that brings you peace of mind. Here's what makes it possible:</p>
              <ul className="dot-list">
                <li>Complimentary Wi-Fi to stay connected</li>
                <li>Built-in folding tables and units to prepare your paperwork</li>
                <li>An option to plug in your device and catch up with your favourite TV show</li>
              </ul>
              <p>Your chauffeur handles the traffic, so you can focus on what matters – being productive or just relaxed - it's up to you.</p>
              <img className="vehicles-img" src={require("../../images/cars/mercedes-s3.webp")}/>
              <h2>Book Your Mercedes S-Class Chauffeur in London & UK</h2>
              <p>Don't just travel - travel in style. Experience the difference our Mercedes S-Class chauffeur service makes. </p>
              <ul className="dot-list">
                <li>Contact us today for a personalized quote.</li>
                <li>Visit our website to learn more about our packages.</li>
                <li>Give us a call to discuss your specific needs.</li>
              </ul>
              <p>Book your Mercedes S-Class chauffeur service now!</p>
              <p>
                <button
                  className="primary-button center"
                  onClick={() => setBookingOpen(!bookingOpen)}
                >
                  Book now
                </button>
              </p>
              <div className="devider"></div>
              <h2 className="faq-section-header">FAQs</h2>
              <div
                className="faq-wrapper"
                itemScope
                itemType="https://schema.org/FAQPage"
              >
                <AccordionComponent
                  question="How many passengers can the Mercedes S-Class comfortably seat?"
                  answer="The Mercedes S-Class comfortably seats four to five passengers. The spacious interior offers enough legroom and headroom for everyone to travel in style and comfort."
                />
                <AccordionComponent
                  question="What is included in your Mercedes S-Class chauffeur package?"
                  answer="Our Mercedes S-Class chauffeur package includes: Professional and experienced chauffeur service, Top-of-the-line Mercedes S-Class vehicle, Complimentary Wi-Fi to stay connected, Bottled water (upon request), Transportation from A to B, as requested"
                />
                <AccordionComponent
                  question="Do your Mercedes S-Class chauffeurs have experience navigating London traffic"
                  answer="Absolutely! Our chauffeurs are highly trained in navigating London's traffic. They prioritize your safety and comfort, ensuring a smooth and efficient journey. They also possess a deep knowledge of the city and its landmarks, offering recommendations if desired."
                />
              </div>
            </div>
          </div>
          <SearchComponent isOpen={bookingOpen} close={() => setBookingOpen(false)}/>
        </main>
        <FooterComponent />
      </div>
    </div>
  );
}

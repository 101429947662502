import React from 'react';
import $ from 'jquery'; 
import "./App.scss";
import { Snackbar } from "@material-ui/core";
import { RentType, CarType } from "../dtos/PriceData";
import { Redirect, RouteComponentProps} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { saveState, saveToken, SummaryActionTypes, UserActionTypes } from "../redux/actions"
import { Preference } from "../dtos/PaymentData";
import { RootState } from "../redux/reducers/rootReducer";
import{ SummaryState } from "../redux/reducers/summaryReducer"
import {  Dispatch } from 'redux';
import HeaderComponent from './HeaderComponent';
import AccordionComponent from '../Components/AccordionComponent';
import FooterComponent from '../Components/FooterComponent';
import SmallAccordionComponent from '../Components/SmallAccordionComponent'
import '@fortawesome/fontawesome-free/css/all.css'
import { Tastemonial } from '../dtos/Tastemonial';
import { tastemonials } from '../config/texts';
import UserDto from '../dtos/UserDto';
import 'react-phone-input-2/lib/style.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MetaTags from 'react-meta-tags';
import SearchComponent from './SearchComponent';

export interface DispatchedProps {
  setState: (state: SummaryState) => void
  saveToken: (user: UserDto) => void
}

export interface IRentState {
  contact: boolean;
  open: boolean;
  errorMsg: string;
  tastemonialOpen: Tastemonial;
  tastemonialFrist: Tastemonial;
  tastemonialLast: Tastemonial;
  gallery?: number;
  carouselInit: boolean;
  bookingOpen: boolean;
}

interface IMappedProps{
  token: string;
}

interface OwnProps {
}

type Props = DispatchedProps & IMappedProps & RouteComponentProps<OwnProps>;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 991 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 991, min: 0 },
    items: 1
  }
};

const responsiveAccordions = {
  mobile: {
    breakpoint: { min: 0, max: 991 },
    items: 1
  }
}

class App extends React.Component<Props, IRentState> {
  
  constructor(props: Props) {
    super(props);
    this.state = {
      contact: false,
      open: false,
      errorMsg: "",
      tastemonialFrist: tastemonials[0],
      tastemonialOpen: tastemonials[1],
      tastemonialLast: tastemonials[2],
      gallery: undefined,
      carouselInit: true,
      bookingOpen: false
    };
  }

  GallerySmallImages(): any {
    var images = [];

    for(let i = 1; i <= 20; i++) {
        images.push(<img key={i} onClick={() => this.toGallery(i)} className={""} id={"img-" + i} src={require('../images/gallery_small' + i + '.webp')} />)
    }

    return images;
  }

  returnTastemonials = () => {
    var x = tastemonials.map((t, i) => {
      return (
        <div key={i} className={this.state.carouselInit && i === 1 ? "tastemonial-card open" : "tastemonial-card closed"}>
          <span className="tastemonial-text">{t.text}</span>
          <span className="tastemonial-name">{t.name}</span>
          <img alt="Pictogram of stars, representing a rating of 5." src={require("../images/pictograms/stars.png")}/>
        </div>
      )                  
    })    
    return x;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  setSearchState(isOpen: boolean) {
    this.setState({bookingOpen: isOpen});
  }

  toGallery = (initial?: number) => {
    this.setState({gallery: initial});
  }

  render() {
    if(this.state.contact)
      return <Redirect push to="/contact" />
    else {
      return (
        <div className="container-width">
          <MetaTags>
            <link rel="canonical" href="https://www.simber.co.uk" />
            <meta name="description" content="The ultimate luxury experience with Simber's VIP chauffeur service in London. 100% discreet service and undeniable comfort. Book now and enjoy the journey." />
            <title>Luxury Chauffeur Services For London & UK | Simber</title>
          </MetaTags>
          <React.Fragment>
          <div className="initial-height">
            <div className="background1">
            <HeaderComponent initial={this.state.gallery} resetInitial={() => this.setState({gallery: undefined})}/>
            <div className='main-screen'>
              <h1 className="slogen"><span>Your partner </span><span>in luxury.</span></h1>
              <button onClick={() => {this.setSearchState(!this.state.bookingOpen);}} className="open-button secondary-button">Book now</button>
            </div>
            <SearchComponent isOpen={this.state.bookingOpen} close={() => this.setSearchState(false)}/>
          </div>
          <span onClick={(c) => {window.scroll({top: window.innerHeight ,behavior:"smooth"})}} className="jump-screen-button"></span>
          </div>
          <div className="home-about-wrapper">
          <div style={{marginTop: '100px'}} className='why-simber-card'>
            <h2 className='why-simber-header'>How Are We Different Than Other Chauffeur Services In London And UK?</h2>
            <span>Simber's VIP chauffeur service elevates luxury and exclusivity to new heights. With meticulous attention to detail, we provide a personalized experience that surpasses every client's expectations. From the moment you step into one of our luxurious vehicles, you'll notice a different level of sophistication and discreet professionalism. Simber ensures a truly unforgettable journey while setting a new standard in luxury transportation.</span>
          </div>
            <div className="accordions">
              <AccordionComponent
                alt="Man helping open car door for passenger."
                titleLevelH3={true}
                oddRow={false}
                title="Long Term Chauffeur" 
                redirect="/long-term-chauffeur"
                img="long_term.webp"
                text="Hire one of our professional chauffeurs for a couple of hours, days or even weeks to accompany you while staying in London. Enjoy the safety, availability, reliability, and consistency while maintaining one point of contact. Our chauffeurs are all extremely polite and professional, they can assist with running daily errands or even booking restaurants as requested."/>
                <AccordionComponent
                oddRow
                alt="Mercedes driving on the highway."
                title="Long Distance Chauffeur" 
                titleLevelH3={true}
                redirect="/long-distance-chauffeur"
                img="long_distance.webp"
                text="Travel safely on long distances with Simber’s door to door chauffeuring service inside or outside of the United Kingdom. Regardless of the destination, we will get you there safely and comfortably."/>
              <AccordionComponent 
                oddRow={false}
                alt="Buisnessman arriving at airport sitting in the car."
                redirect="/airport-transfers"
                title="Airport Transfers" 
                titleLevelH3={true}
                img="airport.webp"
                text="Arrive or depart without any worries knowing your airport transfer will be hassle-free. Upon arrival your Simber chauffeur will meet and greet you with a name board and take you from the terminal straight to the car. Punctual, attentive, safe and trusted service."/>
              <AccordionComponent 
                oddRow
                alt="Chauffeur opening MPV door for passengers."
                redirect="/corporate-chauffeur"
                title="Corporate Chauffeur Service"
                titleLevelH3={true}
                img="corporate.webp"
                text="Arrive at your meeting, roadshow, corporate event, airport, or any destination on time when you travel for business purposes. Simber can also cater to the whole stay of your business guests or delegates, assisting with chauffeured vehicles within the city, long distances or from/to the airport."/>
              <AccordionComponent 
                oddRow={false}
                alt="Exclusive cars waiting for passengers in front of event hosting establishment."
                redirect="/events-chauffeurs"
                title="Event Chauffeuring" 
                titleLevelH3={true}
                img="event.webp"
                text="Hire Simber to organise your travel to events across the UK. Our chauffeurs will be on hand to take care of all of your guests. In addition, any luggage, equipment or personal belongings will be kept safe. If you require a number of chauffeured vehicles for your business partners, colleagues, entourage, friends or family, Simber will provide a coordinator to lead the chauffeur team freeing you from the hassle of logistics."/>
            </div>
            <Carousel
              className="accordions mobile"
              responsive={responsiveAccordions}
              centerMode={false}
              showDots={true}
              partialVisible={false}
              arrows={false}
            >
              <AccordionComponent
                oddRow={false}
                alt="Man helping open car door for passenger."
                title="Long Term Chauffeur" 
                titleLevelH3={true}
                redirect="/long-term-chauffeur"
                img="long_term.webp"
                text="Hire one of our professional chauffeurs for a couple of hours, days or even weeks to accompany you while staying in London. Enjoy the safety, availability, reliability, and consistency while maintaining one point of contact. Our chauffeurs are all extremely polite and professional, they can assist with running daily errands or even booking restaurants as requested."/>
                <AccordionComponent
                oddRow={false}
                title="Long Distance Chauffeur" 
                alt="Mercedes driving on the highway."
                redirect="/long-distance-chauffeur"
                titleLevelH3={true}
                img="long_distance.webp"
                text="Travel safely on long distances with Simber’s door to door chauffeuring service inside or outside of the United Kingdom. Regardless of the destination, we will get you there safely and comfortably."/>
              <AccordionComponent 
                oddRow={false}
                alt="Buisnessman arriving at airport sitting in the car."
                redirect="/airport-transfers"
                title="Airport Transfers" 
                titleLevelH3={true}
                img="airport.webp"
                text="Arrive or depart without any worries knowing your airport transfer will be hassle-free. Upon arrival your Simber chauffeur will meet and greet you with a name board and take you from the terminal straight to the car. Punctual, attentive, safe and trusted service."/>
              <AccordionComponent 
                oddRow={false}
                redirect="/events-chauffeurs"
                alt="Exclusive cars waiting for passengers in front of event hosting establishment."
                title="Event Chauffeuring" 
                titleLevelH3={true}
                img="event.webp"
                text="Hire Simber to organise your travel to events across the UK. Our chauffeurs will be on hand to take care of all of your guests. In addition, any luggage, equipment or personal belongings will be kept safe. If you require a number of chauffeured vehicles for your business partners, colleagues, entourage, friends or family, Simber will provide a coordinator to lead the chauffeur team freeing you from the hassle of logistics."/>
                <AccordionComponent 
                oddRow={false}
                alt="Chauffeur opening MPV door for passengers."
                redirect="/corporate-chauffeur"
                title="Corporate Chauffeur Service"
                titleLevelH3={true}
                img="corporate.webp"
                text="Arrive at your meeting, roadshow, corporate event, airport, or any destination on time when you travel for business purposes. Simber can also cater to the whole stay of your business guests or delegates, assisting with chauffeured vehicles within the city, long distances or from/to the airport."/>
            </Carousel>
            <div className="small-accordions">
              <SmallAccordionComponent
                alt="Icon of joint wedding rings."
                img="wedding_pictogram.png"
                link="wedding-chauffeur"
                title="Wedding Chauffeur Service"
                titleLevelH3={true}
                text="Enjoy and relax on your special day together with your loved ones while being surrounded by the finest chauffeurs and an impressive fleet of cars. Our vehicles will be decorated as per request, and complimentary champagne will be on board waiting for you."/>
              <SmallAccordionComponent
                img="private_pictogram.png"
                alt="Icon of route from point to point."
                link="private-tours"
                title="Private Tours"
                titleLevelH3={true}
                text="Discover the fantastic sights of London or the rest of the United Kingdom. Tailor made tours for you, your family or friends to enjoy and remember. Tour guides speaking different languages are also available to arrange on request. To specify the details of your request, please get in touch for a tailor-made quote."/>
              <SmallAccordionComponent
                img="seaport_pictogram.png"
                alt="Icon of a ferry."
                link="seaport-transfers"
                title="Seaport Transfers"
                titleLevelH3={true}
                text="Hire one of our professional and presentable chauffeurs to assist you in your cruise port transfer, whether from the airport to the cruise line in Dover or Southampton.  have Simber take care of all your transportation requirements for the entire stay in London or the UK."/>
            </div>
            <Carousel
              className="small-accordions mobile"
              responsive={responsiveAccordions}
              centerMode={false}
              showDots={true}
              partialVisible={false}
              arrows={false}
            >
              <SmallAccordionComponent
                img="wedding_pictogram.png"
                alt="Icon of joint wedding rings."
                link="wedding-chauffeur"
                title="Wedding Chauffeur Service"
                titleLevelH3={true}
                text="Enjoy and relax on your special day together with your loved ones while being surrounded by the finest chauffeurs and an impressive fleet of cars. Our vehicles will be decorated as per request, and complimentary champagne will be on board waiting for you."/>
              <SmallAccordionComponent
                img="private_pictogram.png"
                link="private-tours"
                alt="Icon of route from point to point."
                title="Private Tours"
                titleLevelH3={true}
                text="Discover the fantastic sights of London or the rest of the United Kingdom. Tailor made tours for you, your family or friends to enjoy and remember. Tour guides speaking different languages are also available to arrange on request. To specify the details of your request, please get in touch for a tailor-made quote."/>
              <SmallAccordionComponent
                img="seaport_pictogram.png"
                alt="Icon of a ferry."
                link="seaport-transfers"
                title="Seaport Transfers"
                titleLevelH3={true}
                text="Hire one of our professional and presentable chauffeurs to assist you in your cruise port transfer, whether from the airport to the cruise line in Dover or Southampton.  have Simber take care of all your transportation requirements for the entire stay in London or the UK."/>
            </Carousel>
            <div className="why-simber-card">
              <h4 className="why-simber-header">Why Simber?</h4>
              <span className="why-simber-main-text">Simber is much more than just a chauffeur company, we are all about the small touches to make your journey unique.. <br />Our seamless service and safe rides are brought to you by our premium chauffeurs – reliable, professional and discreet with a well-presented fleet of vehicles. </span>
              <div className="why-simber-list">
                <span className="why-simber-list-text">We assist you in the planning of your daily, weekly, or monthly transportation schedules. </span>
                <span className="why-simber-list-text">We are dedicated to our clientele and have the knowledge and ability to deliver on complex requests. </span>
                <span className="why-simber-list-text">Our team can offer a range of concierge services and assist with various booking requests related to dining, entertainment and leisure as well as arranging porters or VIP services at airports.</span>
              </div>
            </div>
            <div className="services">
              <h4 className="sevices-header">With Simber, it is all in the details.</h4>
              <span className="sevices-detail-text">In a contactless ride, you will travel in a safe, disinfected vehicle where all the essentials will be provided to you.</span>
              <div className="services-card">
              <div className="services-card-row"><img alt="Pictogram of a Chauffeur." src={require("../images/pictograms/chauffeur_pictogram.png")}/><span className="services-card-text">Well Presented Chauffeur </span></div>
              <div className="services-card-row"><img alt="Pictogram of a phone charger." src={require("../images/pictograms/charging_pictogram.png")}/><span className="services-card-text">Phone Chargers</span></div>
                <div className="services-card-row"><img alt="Pictogram of cleaning devices." src={require("../images/pictograms/clean_pictogram.png")}/><span className="services-card-text">Clean Vehicle </span></div>
                <div className="services-card-row"><img alt="Pictogram of hand sanitizers." src={require("../images/pictograms/sanitizers_pictogram.png")}/><span className="services-card-text">Hand Sanitizers and tissues in the vehicle </span></div>
                <div className="services-card-row"><img alt="Pictogram of beverages." src={require("../images/pictograms/refreshments_pictogram.png")}/><span className="services-card-text">Refreshments</span></div>
                <div className="services-card-row"><img alt="Pictogram of a car." src={require("../images/pictograms/car_pictogram.png")}/><span className="services-card-text">In Car Entertainment</span></div>
                <div className="services-card-row"><img alt="Pictogram of Wi-Fi service." src={require("../images/pictograms/wifi_pictogram.png")}/><span className="services-card-text">On Board Wifi</span></div>
              </div>
              <span className="services-after-text">No transportation hassle, no journey stress, no time wasted. <br />Experience the luxury of our rides and get to your destination in style.</span>
            </div>
            <div className="tastemonials">
              <h5 className="tastemonials-header">Testimonials</h5>
              <Carousel
                className="testimonials-carousel"
                autoPlay={true}
                autoPlaySpeed={5000}
                responsive={responsive} 
                infinite={true}
                centerMode={false}
                beforeChange={(nextSlide, { currentSlide }) => {
                  var w = $(window).width();
                  var slide = (w! < 911) ? currentSlide : (currentSlide + 1);
                  var cur = document.getElementsByClassName("testimonials-carousel")[0]?.children[0]?.children[slide]?.children[0];
                  if(!cur)
                    return
                  cur.classList.add("closed");
                  cur.classList.remove("open")
                  this.setState({carouselInit: false});
                }}
                afterChange={(previousSlide, { currentSlide }) => {
                  var w = $(window).width();
                  var slide = (w! < 911) ? currentSlide : (currentSlide + 1);
                  var cur = document.getElementsByClassName("testimonials-carousel")[0]?.children[0]?.children[slide]?.children[0];
                  if(!cur)
                    return
                  cur.classList.add("open");
                  cur.classList.remove("closed")
                  this.setState({carouselInit: false});
                }}
              >
                {this.returnTastemonials()}
              </Carousel>
            </div>
            <div className="green">
              <img alt="The logo of The Green Imprint." src={require("../images/green_logo.png")}/>
              <span className="green-text">We care about the planet. We are eco-responsible and act. Simber offsets its carbon footprint by working together with Ecologi, our favourite climate change organisation. We help fund the highest standard climate projects to accelerate our transition to a sustainable future by planting trees for every journey we undertake .</span>
              <span className="green-text-small">We give back to our planet, your chauffeur driven ride is one tree plus.  Do your eco-part with us and help save the planet!</span>
            </div>
            <div className="gallery">
              <div className="gallery-texts-wrapper">
                <h5 className="gallery-header">Gallery</h5>
                <span className="gallery-text">A premium chauffeur ride is a combination of a professional chauffeur, a well-presented vehicle, and a seamless ride.</span>
                <span className="gallery-text-gold">Here is a glimpse at our fleet of vehicles.</span>
              </div>
              <div className="gallery-images">
                {this.GallerySmallImages()}
                <div className="gallery-button-wrapper">
                  <button className="primary-button" onClick={() => this.toGallery(1)}>More</button>
                </div>
              </div>
            </div>
          </div>
          </React.Fragment>
          
          <Snackbar
            open={this.state.open}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="error">
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <FooterComponent />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: RootState): IMappedProps => {
  return{ token: state.user.token}
};

const mapDispatchToProps = (dispatch: Dispatch<(SummaryActionTypes | UserActionTypes)>) => {
  return {
    setState: (state: SummaryState) => dispatch(saveState(state)),
    saveToken: (user: UserDto) => dispatch(saveToken(user))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

import React, { useEffect, useState } from 'react';
import './DatePickerComponent.scss';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ClickAwayListener, Slider } from '@material-ui/core';
import{ getMinDate } from "../../redux/reducers/summaryReducer"
import { MenuItem, Select } from '@mui/material';

export interface IDatePickerProps { 
    valueChanged: (val: Date) => void;
    dateSet: boolean;
    initValue?: Date;
}

export default function DatePickerComponent(props: IDatePickerProps) {
    const [date, setDate] = useState(props.initValue ?? getMinDate())
    const [currentDate, setCurrentDate] = useState(props.initValue ?? getMinDate())
    const [time, setTime] = useState(new Date(getMinDate().setMinutes(getMinDate().getMinutes() + 5)))
    const [minutes, setMinutes] = useState((Math.ceil(time.getMinutes() / 5) * 5));
    const [hours, setHours] = useState(time.getHours() > 12 ? (time.getHours() - 12) : time.getHours());
    const [amPm, setAmPm] = useState(time.getHours() < 12 ? "am" : "pm");
    const [open, setOpen] = useState(false);
    const [dateSectionOpen, setDateSectionOpen] = useState(false);
    const [timeout, setTimeOutVar] = useState<NodeJS.Timeout | undefined>(undefined);

    const timeString = (time: number): string => {
        return (time < 10 ? "0" : "") + time;
    }

    const getHourSelectOptions = () => {
        var options = [];
        for (var i = 0; i <= 12; i++) {
            options.push(<MenuItem key={i} value={i}>{timeString(i)}</MenuItem>)
        }
        return options;
    }

    const getMinuteSelectOptions = () => {
        var options = [];
        for (var i = 0; i <= 60; i+=5) {
            options.push(<MenuItem key={i} value={i}>{timeString(i)}</MenuItem>)
        }
        return options;
    }

    const setHoursManually = (e: EventTarget & HTMLInputElement) => {
        var v = e.value;
        if(v.startsWith("0") && v.length > 1){
            v = v.slice(1);
        }
        setTime(new Date(time.setHours(+v)));
        e.value = v;
    }

    const setMinutesManually = (e: EventTarget & HTMLInputElement) => {
        var v = e.value;
        if(v === "") {
            v = "00";
        }
        if(+v < 10){
            v = ("0" + v)
        }
        if(v.startsWith("00") && v.length > 2){
            v = v.slice(2);
        }
        if(v.startsWith("0") && v.length > 2){
            v = v.slice(1);
        }
        setTime(new Date(time.setMinutes(+v)));
        e.value = v;
    }

    const applyDateTime = () => {
        propagateChange();
        setOpen(false);
    }

    const dateString = () => {
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var strTime = 
            currentDate.getDate() + "/" +
            (currentDate.getMonth() + 1) + "/" +
            currentDate.getFullYear() + ", " + 
            (hours < 12 ? timeString(hours) : timeString(hours - 12)) + ':' + timeString(minutes) +
            " " + (hours < 12 ? "am" : "pm");
        return strTime;
    }

    const dateStringAccordion = () => {
        return date.toLocaleDateString('en-us', {year: "numeric", month: "long", day: "2-digit"})
    }

    const propagateChange = () => {
        var h = hours;
        if(amPm == "pm"){
            h += 12;
        }
        var retDateHours = new Date(date.setHours(h));
        var retDate = new Date(retDateHours.setMinutes(minutes));
        setTime(retDate);
        setCurrentDate(retDate);
        props.valueChanged(retDate);
    }

    return (
        <div className="date-picker-wrapper">
            <div className={open ? "date-picker-input open" : "date-picker-input"} onClick={() => {
                    if(timeout) {
                        clearTimeout(timeout);
                        setTimeOutVar(undefined);
                    }
                    setTimeOutVar(setTimeout(() => setOpen(!open), 10));
                }}
            >
                <span className={props.dateSet ? "" : "unset"}>{dateString()}</span>
                <img className={open ? "rotate" : ""} src={require("../../images/pictograms/arrow_grey.png")} />
            </div>
            { open && 
                <ClickAwayListener
                
                    onClickAway={() => {
                        if(timeout) {
                            clearTimeout(timeout);
                            setTimeOutVar(undefined);
                        }
                        setTimeOutVar(setTimeout(() => setOpen(!open), 10));
                    }}
                >
                    <div className="date-picker-accordion">
                        <div className="input-wrapper date-input">
                            <span onClick={() => setDateSectionOpen(!dateSectionOpen)}>{dateStringAccordion()}</span>
                            <img onClick={() => setDateSectionOpen(!dateSectionOpen)} src={require("../../images/pictograms/arrow_grey_thicc.png")} />
                            {dateSectionOpen &&
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                        disableToolbar
                                        minDate={new Date()}
                                        variant="static"
                                        label="Only calendar"
                                        helperText="No year selection"
                                        value={date}
                                        onChange={(val) => {setDate(val as Date); setDateSectionOpen(false)}}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            }
                        </div>
                        <div className="input-wrapper time-input">
                            <Select
                                className="time-select"
                                value={hours}
                                variant="standard"
                                onChange={(e) => setHours(+e.target.value)}
                                disableUnderline
                                MenuProps={{disablePortal: true}}
                                IconComponent={(props) => {return (<span></span>)}}
                            >
                                {getHourSelectOptions()}
                            </Select>
                            <img className="time-dots" src={require("../../images/pictograms/time_dots.png")}></img>
                            <Select
                                className="time-select"
                                value={minutes}
                                variant="standard"
                                onChange={(e) => setMinutes(+e.target.value)}
                                disableUnderline
                                MenuProps={{disablePortal: true}}
                                IconComponent={(props) => {return (<span></span>)}}
                            >
                                {getMinuteSelectOptions()}
                            </Select>
                        </div>
                        <div className='input-wrapper am-pm'>
                            <Select
                                className="am-pm-select"
                                value={amPm}
                                variant="standard"
                                onChange={(e) => setAmPm(e.target.value)}
                                disableUnderline
                                MenuProps={{disablePortal: true}}
                                IconComponent={(props) => {return (<img src={require("../../images/pictograms/arrow_grey_thicc.png")}></img>)}}
                            >
                                <MenuItem value="am">AM</MenuItem>
                                <MenuItem value="pm">PM</MenuItem>
                            </Select>
                        </div>
                        <button onClick={applyDateTime} className="submit-button primary-button">Apply Date & Time</button>
                        {/* <div className="button-section">
                            <input type="button" value="Date" className={dateSection ? "active" : ""} onClick={() => setDateSection(true)}/>
                            <input type="button" value="Time" className={!dateSection ? "active" : ""} onClick={() => setDateSection(false)}/>
                        </div>
                        <div className="content-section">
                            { dateSection &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                    disableToolbar
                                    minDate={new Date()}
                                    variant="static"
                                    label="Only calendar"
                                    helperText="No year selection"
                                    value={date}
                                    onChange={(val) => {setDate(val as Date);}}
                                    />
                                </MuiPickersUtilsProvider>
                            }
                            {
                                dateSection || 
                                    <div className="time-picker">
                                        <div className="time-display">
                                            <input className="time-display-input" type="number" value={time.getHours()} onChange={(e) => setHoursManually(e.currentTarget)} />
                                            <img alt="Double dots between hours and minutes" src={require("../images/pictograms/time_dots.png")}/>
                                            <input className="time-display-input" type="number" value={time.getMinutes()} onChange={(e) => setMinutesManually(e.currentTarget)} />
                                        </div>
                                        <div className="time-slider-wrapper">
                                            <span>Hours</span>
                                            <Slider
                                                value={time.getHours()}
                                                min={0}
                                                max={24}
                                                onChange={(e,v) => {setTime(new Date(time.setHours(v as number)));}}
                                                onChangeCommitted={() => propagateChange()}
                                            />
                                        </div>
                                        <div className="time-slider-wrapper">
                                            <span>Minutes</span>
                                            <Slider
                                                value={time.getMinutes()}
                                                min={0}
                                                max={60}
                                                onChange={(e,v) => {setTime(new Date(time.setMinutes(v as number)));}}
                                                onChangeCommitted={() => propagateChange()}
                                            />
                                        </div>
                                        <input
                                            className="done-button"
                                            onClick={() =>{
                                                if(timeout) {
                                                    clearTimeout(timeout);
                                                    setTimeOutVar(undefined);
                                                }
                                                setTimeOutVar(setTimeout(() => setOpen(!open), 10));}} type="button" value="Done" 
                                        />
                                    </div>
                            }
                        </div> */}
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}